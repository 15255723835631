export default {
    data() {
        return {
            
        }
    },
    methods: {
        onFocus(e) {
            e.currentTarget.classList.toggle('focus')
        },
        onBlur(e) {
            e.currentTarget.classList.toggle('focus')
        },
        numFilter(e) {
            !/[0-9]/.test(e.key) && e.preventDefault()
        },
        filterMinMax(e) {
            const self = e.currentTarget
            if(self.value < parseFloat(self.getAttribute('data-min'))) {
                self.value = self.getAttribute('data-min')
            }
            if(self.value > parseFloat(self.getAttribute('data-max'))) {
                self.value = self.getAttribute('data-max')
            }
        },
        setDefault(e) {
            const self = e.currentTarget
            if(self.value.length < 1) {
                self.value = self.getAttribute('data-default')
                return
            }
        }
    }
}