import { productsSliderInit, cardsSliderInit } from '../../sliders'

export default {
    name: 'NotFound',
    data() {
        return {}
    },
    mounted() {
        productsSliderInit()
        cardsSliderInit()
    },
}
