export default {
    props: ['value', 'error', 'name', 'disabled', 'filtering'],
    data() {
        return {
            active: false,
            model: null,
            display: '',
            loaded: false,
            items: [],
            current: {},
            search: '',
        }
    },
    mounted() {
        this.$refs.list.querySelectorAll('.select-item').forEach((el) => {
            if (el.getAttribute('selected') === 'selected') {
                this.display = el.innerText.trim()
                this.$emit('input', el.getAttribute('data-value').trim())
            }
            this.items.push({
                value: el.getAttribute('data-value').trim(),
                display: el.innerText.trim(),
            })
        })
        this.loaded = true
    },
    methods: {
        showToggle(e) {
            const el = e.currentTarget
            setTimeout(() => {
                if (this.active) {
                    if (el.classList.contains('active')) {
                        this.hide()
                    }
                } else {
                    this.onFocus()
                }
            }, 100)
        },
        onFocus() {
            this.active = true
            if (document.documentElement.clientWidth <= 768) {
                document.querySelector('body').classList.add('overflow')
            }
            setTimeout(() => {
                this.$refs.control.classList.add('active')
            }, 100)
        },
        onBlur(e) {
            this.hide()
        },
        selectOption(e) {
            let item = null
            if (e.target.classList.contains('select-item')) {
                item = e.target.classList.contains('select-item')
            }
            if (e.target.closest('.select-item')) {
                item = e.target.closest('.select-item')
            }
            if (item) {
                this.model = item.getAttribute('data-value')
                this.display = item.innerText.trim()
                this.$emit('input', item.getAttribute('data-value'))
                this.$emit('change', { name: this.name, data: item.getAttribute('data-value').trim() })
                setTimeout(() => {
                    this.filter({ target: { value: '' } })
                }, 300)
                this.hide()
            }
        },
        setValue(val) {
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].value === val) {
                    this.model = this.items[i].value
                    this.display = this.items[i].display
                    break
                }
            }
        },
        change() {
            this.$emit('change', { name: this.name, data: this.model })
        },
        show() {
            this.active = true
            if (document.documentElement.clientWidth <= 768) {
                document.querySelector('body').classList.add('overflow')
            }
            setTimeout(() => {
                this.$refs.control.classList.add('active')
            }, 100)
        },
        hide() {
            setTimeout(() => {
                this.active = false
                if (document.documentElement.clientWidth <= 768) {
                    document.querySelector('body').classList.remove('overflow')
                }
                this.$refs.select.blur()
                this.$refs.control.blur()
                this.$refs.control.classList.remove('active')
                this.$refs.control.querySelector('.select-input').blur()
                this.$refs.control.querySelector('.select-input').classList.remove('active')
                if (this.$refs.list.querySelector('.select-item.active')) {
                    this.$refs.list.querySelector('.select-item.active').classList.remove('active')
                }
            }, 100)
        },
        reset() {
            this.$emit('input', '')
            this.display = ''
        },
        filter(e) {
            this.search = e.target.value
            const val = this.search
            const rows = this.$refs.list.querySelectorAll('.select-item')
            const regex = new RegExp(val, 'gi')

            rows.forEach((row) => {
                const text = row.innerText.trim()
                if (text.match(regex) === null) {
                    row.style.display = 'none'
                } else {
                    row.style.display = null
                    row.innerHTML = text.replace(regex, `<b>${text.match(regex)[0]}</b>`)
                }
            })
        },
        updateOptions(data = [], options) {
            this.items = data
            let rows = ''

            if (data) {
                data.forEach((row, i) => {
                    rows += `<div class="select-item" data-value="${row[options.key || 'key']}" ${
                        i === 0 ? 'selected="selected"' : ''
                    }>${row[options.display || 'name']}</div>`
                })
            }

            this.$refs.list.innerHTML = rows
        },
        keyboardNav(e) {
            if (e.keyCode == 40) {
                e.preventDefault()
                const options = this.$refs.list.querySelectorAll('.select-item')
                const current = this.$refs.list.querySelector('.select-item.active')

                if (!current) {
                    options[0].classList.add('active')
                } else {
                    for (let i = 0; i < options.length; i++) {
                        if (options[i].classList.contains('active') && i + 1 < options.length) {
                            options[i].classList.remove('active')
                            options[i + 1].classList.add('active')
                            break
                        }
                    }
                }
            }
            if (e.keyCode == 38) {
                e.preventDefault()
                const options = this.$refs.list.querySelectorAll('.select-item')
                const current = this.$refs.list.querySelector('.select-item.active')

                if (!current) {
                    options[0].classList.add('active')
                } else {
                    for (let i = 0; i < options.length; i++) {
                        if (options[i].classList.contains('active') && i - 1 >= 0) {
                            options[i].classList.remove('active')
                            options[i - 1].classList.add('active')
                            break
                        }
                    }
                }
            }
            if (e.keyCode == 13) {
                e.preventDefault()
                const el = this.$refs.list.querySelector('.select-item.active')
                if (!el) return
                const val = el.getAttribute('data-value')
                this.$emit('input', val)
                this.$emit('change', { name: this.name, data: val })
                this.display = this.$refs.list.querySelector('.select-item.active').innerText
                this.hide()
            }
        },
    },
}
