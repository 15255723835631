import { productsSliderInit, cardsSliderInit } from '../../sliders'
import { anchorScroll } from '../../../utils/anchorScroll'

export default {
    name: 'Article',
    data() {
        return {}
    },
    mounted() {
        productsSliderInit()
        cardsSliderInit()
        anchorScroll('.article-nav', 32)

        const sectionObserve = {
            options: {
                threshold: [0, 0.25, 0.5, 0.75, 1], //0.25
                rootMargin: '-50% 0% -50% 0%',
            },
            callback: (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const h = entry.boundingClientRect.height
                        const top = window.scrollY - entry.intersectionRect.top
                        let percent = Math.round((top / h) * 100)

                        if (h - top > h) {
                            percent = 0
                        } else if (h - top < 0) {
                            percent = 100
                        }

                        document.querySelector('.read-progress-line-inner').style.width = `${percent}%`
                        document.querySelector('.read-progress-num').innerText = percent
                    }
                })
            },
            targets: [...document.querySelectorAll('.article-content')],
        }
        window.addEventListener('scroll', e => {
            this.observing(sectionObserve.targets, sectionObserve.callback, sectionObserve.options)
        })
    },
    methods: {
        observing(targets = [], callback, options) {
            const observer = new IntersectionObserver(callback, options)
            if (targets.length > 0) {
                targets.forEach((el) => observer.observe(el))
            }
        },
    }
}