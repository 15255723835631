import FilterSection from './filterSection'

export default {
    name: 'VFilter',
    components: {
        FilterSection,
    },
    data() {
        return {
            selected: []
        }
    },
    mounted() {
        document.querySelector('.filter-toggle-btn').addEventListener('click', (e) => {
            e.preventDefault()
            document.querySelector('.filter').classList.toggle('active')
        })
    },
    methods: {
        close() {
            document.querySelector('.filter').classList.remove('active')
        },
        toggle(e) {
            const rows = document.querySelectorAll('.filter-section-title span')
            rows.forEach(row => {
                if (row.innerText.trim() === e.currentTarget.querySelector('.filter-selected-row-title').innerText.trim()) {
                    row.closest('.filter-section-wrap').classList.add('active')
                    row.closest('.fliter-section').querySelector('.filter-section-body').classList.add('active')
                }
            })
        },
        reset() {
            this.$children.forEach(cmp => {
                if (typeof cmp.filterSectionReset === 'function') {
                    cmp.filterSectionReset()
                }
            })
        },
        touchHandle(e) {
            let start = 0
            let move = 0

            start = e.changedTouches[0].clientX

            const btn = e.currentTarget.querySelector('.drag-reset-btn')

            e.currentTarget.addEventListener('touchmove', e => {
                move = this.touchMove(e, start, btn)
            })

            e.currentTarget.addEventListener('touchend', e => {
                this.touchEnd(e, btn)
            })

        },
        touchMove(e, start, btn) {
            btn.style.width = `${start - e.changedTouches[0].clientX}px`
            return start - e.changedTouches[0].clientX
        },
        touchEnd(e, btn) {
            if (btn.offsetWidth > document.documentElement.clientWidth / 2) {
                btn.style.transition = `all .3s ease 0s`
                setTimeout(() => {
                    btn.style.width = `100%`
                    btn.innerText = btn.getAttribute('data-completed')
                })
                setTimeout(() => {
                    btn.style.transition = `all 1s ease 0s`
                    btn.style.transform = `translateY(-100%)`
                    btn.style.opacity = 0
                    btn.closest('.filter-selected-row').querySelector('.filter-selected-row-col').style.opacity = 0

                    setTimeout(() => {
                        const key = btn.closest('.filter-selected-row').querySelector('.filter-selected-row-title').innerText.trim()
                        this.selected.forEach((row, i) => {
                            if (row.name === key) {
                                this.selected.splice(i, 1)
                            }
                        })

                        this.$children.forEach(row => {
                            if (typeof row.filterSectionReset === 'function') {
                                const needleKey = row.$el.querySelector('.filter-section-title span').innerText.trim()
                                if (needleKey === key) {
                                    row.filterSectionReset()
                                }
                            }
                        })

                    }, 600)


                }, 300)
            } else {
                btn.style.transition = `all .3s ease 0s`
                setTimeout(() => {
                    btn.style.width = '0px'
                })
                setTimeout(() => {
                    btn.style.width = null
                    btn.style.transition = null
                }, 300)
            }
        }
    }
}