import axios from "axios"

export default {
    name: 'ChangeCity',
    data() {
        return {
            loading: false,
            timeout: null,
            cities: [],
            defaultCities: [],
            input: {
                value: '',
                error: false,
                showErrors: false,
            }
        }
    },
    computed: {
        list() {
            return this.cities.length > 0 ? this.cities : this.defaultCities
        }
    },
    mounted() {
        const rows = this.$refs.cities.querySelectorAll('.change-cities-item')
        if(rows.length > 0) {
            rows.forEach(row => {
                this.defaultCities.push({
                    id: row.getAttribute('data-id'),
                    name: row.getAttribute('data-name'),
                    value: row.innerText
                })
            })
        }
        this.$refs.cities.addEventListener('click', (e) => {
            if(e.target.closest('.change-cities-item')) {
                this.$emit('change', {
                    id: e.target.getAttribute('data-id'),
                    value: e.target.getAttribute('data-name'),
                })
            }
        })
    },
    methods: {
        getData(e) {
            if(e.target.value.length === 0) {
                this.updateList(this.defaultCities, '')
                this.input.error = false
                this.input.showErrors = false
                this.loading = false
                return
            }
            const input = e.target
            const url = input.getAttribute('data-url')
            const params = {}
            params[input.name] = e.target.value

            axios.get(url, {
                params: params
            }).then(response => {
                if(!response.data.success) {
                    this.input.error = true
                    this.input.showErrors = true
                    return
                }
                this.input.error = false
                this.input.showErrors = false
                this.updateList(response.data.data, e.target.value)
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.error(err)
            })
        },
        onInput(e) {
            this.loading = true
            this.debounce(() => this.getData(e), e.target.getAttribute('data-debounce') || 1000)
        },
        debounce(func, timeout) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                func()
            }, timeout)
        },
        updateList(rows, val = '') {
            let html = ''
            if(rows.length > 0) {
                const regex = new RegExp(val, 'gi')
                rows.forEach(row => {
                    let display = row.value
                    const text = row.value
                    if (text.match(regex) === null) {
                        display = display
                    } else {
                        let tmp = text.split(' - ')                        
                        tmp[0] = tmp[0].replace(regex, `<b>${tmp[0].match(regex)[0]}</b>`)
                        display = tmp.join(' - ')
                    }
                    html += `<div class="change-cities-item" data-id="${row.id}" data-name="${row.name}">${display}</div>`
                })
                this.$refs.cities.innerHTML = html
            }
        }
    }
}