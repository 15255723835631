<template>
    <div class="progress-bar-wrap" v-show="show">
        <div class="progress-bar"></div>
        <div class="progress-bar-backdrop"></div>
    </div>
</template>

<script>
const inBrowser = typeof window !== 'undefined'
export default {
    name: 'ProgressBar',
    props: {
        size: {
            default: '70px',
        },
        name: {
            default: '',
        },
    },
    computed: {
        styles() {
            return {
                width: this.size,
                height: this.size,
            }
        },
        show() {
            if (this.name !== this.loading.options.name) {
                return false
            } else {
                return this.loading.options.show
            }
        },
        loading() {
            if (inBrowser) {
                return window.PageLoaderEventBus.LOADING
            } else {
                return {
                    show: false,
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
.progress-bar-wrap {
    position: sticky;
    left: 0;
    top: 0;
    height: 0;
    width: 100%;
    z-index: 100;
}
.progress-bar {
    position: relative;
    height: 4px;
    width: 100%;
    overflow: hidden;
    background: #ebebeb;
    z-index: 100;

    &::before,
    &::after {
        background: #ffc400;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: 0 0;
    }
    &::before {
        animation: linear-progress-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    &::after {
        animation: linear-progress-indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 0s;
        animation-delay: 1.15s;
        transform: translate3d(-101%, 0, 0) scaleX(1);
    }
}
.progress-bar-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
}

@keyframes linear-progress-indeterminate {
    0% {
        transform: translate3d(-35%, 0, 0) scaleX(0.35);
    }
    60% {
        transform: translate3d(100%, 0, 0) scaleX(0.9);
    }
    100% {
        transform: translate3d(100%, 0, 0) scaleX(0.9);
    }
}
@keyframes linear-progress-indeterminate-short {
    0% {
        transform: translate3d(-101%, 0, 0) scaleX(1);
    }
    60% {
        transform: translate3d(107%, 0, 0) scaleX(0.01);
    }
    100% {
        transform: translate3d(107%, 0, 0) scaleX(0.01);
    }
}
@media screen and (max-width: 768px) {
    .progress-bar-wrap {
        position: fixed;
    }
}
</style>
