import axios from 'axios'

export default {
    name: 'Subscribes',
    data() {
        return {
            formData: {
                email_news: false,
                sms_news: false,
                viber_news: false,
                browser_news: false,
                email_blog: false,
                sms_blog: false,
                viber_blog: false,
                browser_blog: false,
            },
        }
    },
    mounted() {
        document.querySelectorAll('input[type=checkbox]').forEach((input) => {
            this.formData[input.name] = input.checked
        })
    },
    methods: {
        onChange(e) {
            this.formData[e.target.name] = e.target.checked
            this.$refs.subscribesForm.dispatchEvent(new Event('submit'))
        },
        onSubmit(e) {
            e.preventDefault()

            axios({
                url: this.$refs.subscribesForm.getAttribute('action'),
                method: this.$refs.subscribesForm.getAttribute('method'),
                data: this.formData,
            })
                .then({})
                .catch((err) => console.err(err))
        },
    },
}
