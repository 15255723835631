import { numberFormat } from '../../utils'
import VueSlider from 'vue-slider-component'

export default {
    data() {
        return {
            active: false,
        }
    },
    components: {
        VueSlider,
    },
    props: {
        id: {
            type: Number,
        },
        productPrice: {
            type: Number,
            default: 0,
        },
        model: {
            type: Number,
            default: 2,
        },
        value: {
            type: Number,
            default: 2,
        },
        creditor: {
            type: Number,
        },
        min: {
            type: Number,
            default: 2,
        },
        max: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        sliderOptions() {
            return { min: this.min, max: this.max, height: 2, dotSize: 24 }
        },
    },
    methods: {
        numberFormat(number, n, x, s, c) {
            return numberFormat(number, n, x, s, c)
        },
        changeVal(val) {
            this.$emit('update-credit-parts', val)
        },
        checkRadio(e) {
            this.$emit('update-creditor', { min: this.min, id: e.target.value })
        },
    },
}
