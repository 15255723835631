export default {
    props: {
        id: {
            type: Number,
        },
        value: {},
        model: {
            type: Object,
        },
        props: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {
                city: {
                    value: '',
                    error: false,
                    showError: false,
                    name: 'city',
                },
                warehouse: {
                    value: '',
                    error: true,
                    showError: false,
                    name: 'warehouse',
                    rule: 'warehouse',
                    validate: true,
                },
            },
            descr: null,
        }
    },
    mounted() {
        if (this.$refs.input.getAttribute('checked') === 'checked') {
            this.$refs.input.checked = true
            this.onChange()
        }
    },
    watch: {
        value(val) {
            if (val.delivery !== this.id) {
                for (let k in this.formData) {
                    if (!this.formData[k]) return
                    if (this.formData[k].hasOwnProperty('error')) {
                        this.formData[k].showError = false
                    }
                }
            }
        },
    },
    methods: {
        onChange() {
            this.formData.city = this.props.city
            this.$emit('input', this.formData)
        },
        changeWarehouse(data) {
            if (data.data.length > 0) {
                this.formData.warehouse.error = false
                this.formData.warehouse.showError = false
            } else {
                this.formData.warehouse.error = true
                this.formData.warehouse.showError = true
            }
            const items = this.$refs.warehouses.items
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].id == data.data) {
                        this.descr = items[i].descr
                    }
                }
            }
        },
    },
}
