import Vue from 'vue'

import './assets/css/app.scss'
import 'swiper/swiper-bundle.min.css'
import 'vue-slider-component/theme/antd.css'

import Select from './components/ui/select'
import Tooltip from './components/ui/tooltip'
import Expand from './components/ui/expand.vue'
import Input from './components/ui/input'

import ProgressBar from './components/ProgessBar/index'
import VModal from 'vue-js-modal'
import Modal from './components/modal.vue'
import { mask } from 'vue-the-mask'
// import VueTheMask from 'vue-the-mask'
// Vue.use(VueTheMask)
// import Menu from './components/menu'

// import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false

import App from './App.js'

Vue.use(ProgressBar, { show: false })
Vue.use(VModal)

Vue.component('combobox', Select)
Vue.component('tooltip', Tooltip)
Vue.component('expand', Expand)
Vue.component('v-input', Input)
Vue.component('v-modal', Modal)
// Vue.component('v-menu', Menu)
Vue.directive('mask', mask)

new Vue({
    components: {
        app: App,
    },
    // render: (h) => h(App),
}).$mount('#app')
