import Swiper from 'swiper/bundle'
import { productsSliderInit, cardsSliderInit, brandsSwiperInit } from '../../sliders'
import SelectionProducts from './selectionProducts'

export default {
    name: 'Home',
    data() {
        return {
            textMore: '',
        }
    },
    components: {
        'selection-products': SelectionProducts,
    },
    mounted() {
        this.heroSwiperInit()
        brandsSwiperInit(this)
        productsSliderInit()
        cardsSliderInit()

        document.addEventListener('DOMContentLoaded', () => {
            if (typeof this.$refs.page_content !== 'undefined') {
                if (this.$refs.page_content.offsetHeight > 110) {
                    this.$refs.page_text_more.classList.add('active')
                }
            }
        })
    },
    methods: {
        heroSwiperInit() {
            new Swiper('.hero-slider', {
                autoplay: {
                    delay: 3000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            })
        },
        pageTextToggle(e) {
            const showText = e.target.getAttribute('data-show')
            const hideText = e.target.getAttribute('data-hide')
            if (this.textMore === '') {
                e.target.innerText = hideText
                this.textMore = 'active'
                this.$refs.page_content_wrap.style.height = `${this.$refs.page_content.offsetHeight}px`
            } else {
                this.textMore = ''
                this.$refs.page_content_wrap.style.height = null
                e.target.innerText = showText
            }
        },
    },
}
