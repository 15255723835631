import { pagin } from '../../pagin'
import Vue from 'vue'

export default {
    name: 'Orders',
    data() {
        return {
            pagin: {
                paginate: null,
                limit: 24,
            },
        }
    },
    mounted() {
        this.initPagin()
    },
    methods: {
        getOrderHistory(id) {
            this.$modal.show('orderHistory', { id: id })
        },
        getOrderCancel(id) {
            this.$modal.show('orderCancel', { id: id })
        },
        initPagin() {
            this.pagin.paginate = pagin
            return this.pagin.paginate({
                wrapper: '.orders-section',
                container: '.orders-list',
                btn: '.load-more-btn',
                limit: this.pagin.limit,
                returnHTML: true,
                onStart: () => {
                    this.$Loading.start('main')
                },
                onLoad: (state, response) => this.updateLoadMoreBtn(state, response),
                onAppendResults: (response, state) => this.insertResults(response, state),
            })
        },
        updateLoadMoreBtn(state, response) {
            if (response.data.total - state.total > state.limit) {
                state.btnMore.querySelector('span').innerText = state.limit
            } else {
                state.btnMore.querySelector('span').innerText = response.data.total - state.total
            }
            setTimeout(() => {
                this.$Loading.finish('main')
            }, 300)
        },
        insertResults(response, state) {
            const NewComponent = Vue.extend({
                template: '<div>' + response.data.data + '</div>',
            })
            const compiled = new NewComponent().$mount()
            document.querySelector('.orders-list').append(compiled.$el)
            this.$forceUpdate()
        },
    },
}
