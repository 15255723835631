import { productsSliderInit, brandsSwiperInit } from '../../sliders'

export default {
    name: 'Brands',
    components: {
    },
    data() {
        return {
            textMore: '',
            loaded: false,
            currentSortby: '',
            scroll: 0,
            sort: {
                value: null,
                name: 'sort',
            },
        }
    },
    mounted() {
        this.loaded = true
        productsSliderInit()
        brandsSwiperInit(this)

        if (document.documentElement.clientWidth < 1024) {
            const rect = document.querySelector('.page-header').getBoundingClientRect()
            const start = rect.top + rect.height + scrollY
            window.addEventListener('scroll', () => {
                if (window.scrollY > start) {
                    this.$refs.alphabet.classList.add('sticky')
                } else {
                    this.$refs.alphabet.classList.remove('sticky')
                }
            })
        }

        let s = false
        window.addEventListener('scroll', () => {
            if (s) {
                this.floatHeader('hide')
            }
            s = true
        })

        window.addEventListener('scroll', () => {
            const rectTop = this.$refs.alphabetHeader.getBoundingClientRect().top + scrollY
            const rows = document.querySelectorAll(".brands-list-row")
            if (rows.length === 0) return

            let f = false
            rows.forEach(row => {
                const rowRect = row.getBoundingClientRect()
                if (rectTop > rowRect.top + scrollY && rectTop < rowRect.top + scrollY + rowRect.height) {
                    document.querySelector('.alphabet-current').innerText = row.querySelector('.brands-list-row-title').innerText
                    f = true
                }
            })
            if (!f) {
                document.querySelector('.alphabet-current').innerText = ''
            }
        })
    },
    methods: {
        selectLetter(e) {
            const rows = this.$refs.brands.querySelectorAll('.brands-list-row')
            rows.forEach(row => {
                if (e.target.value === 'all') {
                    row.style.display = null
                } else if (row.getAttribute('data-value') === e.target.value) {
                    row.style.display = null
                } else {
                    row.style.display = 'none'
                }
            })
        },
        selectGroup(e) {
            if (e.currentTarget.getAttribute('data-group')) {
                e.preventDefault()
                const rows = document.querySelectorAll('.alphabet-group')
                if (rows.length === 0) return

                if (e.currentTarget.closest('.alphabet-item').classList.contains('active')) {
                    this.$refs.nav.querySelectorAll('.alphabet-item').forEach(row => row.classList.remove('active'))
                    rows.forEach(row => row.style.display = 'none')

                    return
                }
                rows.forEach(row => {
                    if (row.getAttribute('data-group') === e.currentTarget.getAttribute('data-group')) {
                        row.style.display = 'flex'
                    } else {
                        row.style.display = 'none'
                    }
                })
            }
            this.$refs.nav.querySelectorAll('.alphabet-item').forEach(el => el.classList.remove('active'))
            e.currentTarget.closest('.alphabet-item').classList.add('active')

        },
        floatHeader(hideClass) {
            if (window.pageYOffset > this.scroll) {
                this.$refs.alphabet.classList.add(hideClass)
                this.scroll = window.pageYOffset
            }
            if (window.pageYOffset < this.scroll) {
                this.$refs.alphabet.classList.remove(hideClass)
                this.scroll = window.pageYOffset
            }
        },
        showItems(e) {
            const items = e.target.closest('.brands-list-row').querySelectorAll('.brands-list-item')
            if (items.length === 0) return

            items.forEach(item => item.classList.remove('hidden'))
            e.currentTarget.style.display = 'none'
        }
    },
}
