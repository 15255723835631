import axios from 'axios'

export default {
    data() {
        return {
            loaded: false,
            formData: {
                type: {
                    name: 'type',
                    value: null,
                    error: false,
                    disabled: false,
                },
                brand: {
                    name: 'brand',
                    value: null,
                    error: false,
                    disabled: true,
                },
                model: {
                    name: 'model',
                    value: null,
                    error: false,
                    disabled: true,
                },
                engine: {
                    name: 'engine',
                    value: null,
                    error: false,
                    disabled: true,
                },
            },
        }
    },
    computed: {
        resetToggle() {
            let show = false
            for(let k in this.formData) {
                if(this.formData[k].value !== null && this.formData[k].value !== '') {
                    show = true
                    break
                } 
            }
            return show
        }
    },
    mounted() {
        this.loaded = true
    },
    methods: {
        reset() {
            for(let k in this.formData) {
                this.$refs[k].reset()
                if(k !== 'type') {
                    this.formData[k].disabled = true
                    this.formData[k].error = false
                    this.$refs[k].$refs.list.innerHTML = ''
                }
            }
        },
        onChange(data) {
            for(let k in this.formData) {
                if(this.formData[k].name === data.name) {
                    if(data.data === null && data.data === '') {
                        this.formData[k].error = true
                    } else {
                        this.formData[k].error = false
                    }
                    break
                }                    
            }
            const endpoints = {
                type: 'brand',
                brand: 'model',
                model: 'engine'
                
            }

            const url = `/api/${endpoints[data.name]}.json`

            axios.get(url).then((response) => {
                let disabled = false
                for(let k in this.formData) {
                    if(disabled) {
                        this.formData[k].disabled = true
                        this.$refs[k].reset()
                    }
                    if(k === data.name) {
                        disabled = true
                    }
                }

                this.formData[endpoints[data.name]].disabled = false
                let html = ``
                for(let k in response.data) {
                    html += `<div
                    class="select-item"
                    data-value="${k}"
                    @click="selectOption($event)"
                >${response.data[k]}</div>`
                }
                const list = document.querySelector(`input[name=${endpoints[data.name]}]`).closest('.select').querySelector('.select-list')
                list.innerHTML = html
            })
        },
        formSend(e) {
            e.preventDefault()
            let error = false

            for(let k in this.formData) {
                if(this.formData[k].value === null || this.formData[k].value === '') {
                    if(!this.formData[k].disabled) {
                        this.formData[k].error = true
                    }
                    error = true
                }
            }

            if(error) return

            let data = {}
            for(let k in this.formData) {
                data[k] = this.formData[k].value
            }

            axios.get(e.currentTarget.getAttribute('action'), {
                data: data
            }).then((response) => {
                this.reset()
                alert(`Send data: ${JSON.stringify(data)}`)
            })
        }
    },
}