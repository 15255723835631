export default {
    data() {
        return {
            config: {
                navSelector: '.tabs-nav-item',
                contentSelector: '.tabs-content',
            },
            nav: [],
            content: [],
        }
    },
    mounted() {
        this.nav = this.$refs.nav.querySelectorAll(this.config.navSelector)
        this.content = this.$refs.body.querySelectorAll(this.config.contentSelector)
        this.nav.forEach((el, i) => {
            el.addEventListener('click', (e) => {
                this.selectTab(e, i)
            })
        })
    },
    methods: {
        selectTab(e, i) {
            this.nav.forEach((el) => el.classList.remove('active'))
            this.content.forEach((el) => el.classList.remove('active'))
            e.currentTarget.classList.add('active')
            this.content[i].classList.add('active')
        },
    },
}
