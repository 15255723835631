import Swiper from 'swiper'

export default {
    data() {
        return {
            gallerySlides: [],
            gallerySlidesNav: [],
            activeIndex: 0,
        }
    },
    mounted() {
        document.addEventListener('click', (e) => {
            if (e.target.hasAttribute('data-gallery') || e.target.closest('[data-gallery]')) {
                const gallery = e.target.closest('[data-gallery]')
                if (e.target.hasAttribute('data-gallery-item') || e.target.closest('[data-gallery-item]')) {
                    e.preventDefault()
                    const slides = gallery.querySelectorAll('[data-gallery-item]')
                    slides.forEach((slide, i) => {
                        if (slide === e.target || slide === e.target.closest('[data-gallery-item]')) {
                            this.activeIndex = i
                        }
                    })
                    this.galleryOpen(slides)
                }
            }
        })
    },
    methods: {
        galleryOpen(data) {
            let slides = []
            let slidesNav = []
            if (data) {
                data.forEach((el) => {
                    if (el.getAttribute('data-type') === 'video') {
                        slides.push(
                            `<div class="swiper-slide">
                            <div class="gallery-video-player">
                            <iframe class="gallery-video" src="${el.getAttribute(
                                'data-src'
                            )}?autohide=1&fs=1&rel=0&hd=1&wmode=transparent&enablejsapi=1&html5=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </div>`
                        )
                        slidesNav.push(
                            `<div class="swiper-slide"><div class="slide-inner video"><svg width="28" height="28" class="icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/svg/sprite.svg#video_dark"></use></svg></div></div>`
                        )
                    } else {
                        slides.push(`<div class="swiper-slide"><img src="${el.getAttribute('data-src')}"></div>`)
                        slidesNav.push(
                            `<div class="swiper-slide"><div class="slide-inner"><img src="${el.getAttribute(
                                'data-src'
                            )}" /></div></div>`
                        )
                    }
                })
            }
            this.gallerySlides = slides
            this.gallerySlidesNav = slidesNav
            this.$modal.show('galleryModal')
        },
        gallerySliderInit() {
            setTimeout(() => {
                document
                    .querySelector('.gallery-slider-nav .swiper-wrapper')
                    .insertAdjacentHTML('beforeend', this.gallerySlidesNav.join(''))

                document
                    .querySelector('.gallery-slider .swiper-wrapper')
                    .insertAdjacentHTML('beforeend', this.gallerySlides.join(''))

                const galleryNav = new Swiper('.gallery-slider-nav', {
                    direction: 'vertical',
                    slidesPerView: 12,
                    spaceBetween: 8,
                    on: {
                        afterInit: (e) => {
                            setTimeout(() => {
                                e.slides.forEach((el, i) => {
                                    if (i === this.activeIndex) {
                                        el.classList.add('swiper-slide-active')
                                    } else {
                                        el.classList.remove('swiper-slide-active')
                                    }
                                })
                            }, 100)
                        },
                    },
                })
                const galleryMain = new Swiper('.gallery-slider', {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    initialSlide: this.activeIndex,
                    breakpoints: {
                        768: {
                            pagination: {
                                el: '.swiper-pagination-gallery',
                                clickable: true,
                                dynamicBullets: true,
                                dynamicMainBullets: 4,
                            },
                        },
                        1024: {
                            pagination: {},
                        },
                    },
                })
                galleryNav.slides.forEach((slide, i) => {
                    slide.addEventListener('mouseover', () => {
                        galleryNav.slideTo(i)
                        galleryNav.slides.forEach((el) => el.classList.remove('swiper-slide-active'))
                        slide.classList.add('swiper-slide-active')

                        galleryMain.slideTo(i)
                    })
                })
                galleryMain.on('slideChange', (e) => {
                    if (document.documentElement.clientWidth > 768) {
                        galleryNav.slideTo(e.activeIndex)
                        galleryNav.slides.forEach((el) => el.classList.remove('swiper-slide-active'))
                        galleryNav.slides[e.activeIndex].classList.add('swiper-slide-active')
                    }

                    const videos = e.$el[0].querySelectorAll('.gallery-video')
                    videos.forEach((el) => {
                        el.setAttribute('src', el.getAttribute('src').replace('&autoplay=1', ''))
                    })

                    if (e.slides[e.activeIndex]) {
                        const slideVideo = e.slides[e.activeIndex].querySelector('.gallery-video')
                        if (slideVideo) {
                            slideVideo.setAttribute('src', `${slideVideo.getAttribute('src')}&autoplay=1`)
                        }
                    }
                })
            }, 300)
        },
    },
}
