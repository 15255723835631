import axios from 'axios'

export default {
    data() {
        return {
            msgGallery: {
                limit: 0,
                page: 1,
                total: 0,
            },
        }
    },
    mounted() {
        if (this.$refs.msgGallery) {
            this.msgGallery.total = this.$refs.msgGallery.querySelectorAll('[data-gallery-item]').length
            this.$refs.msgGallery.addEventListener('click', (e) => {
                let btn = e.target.closest('.review-gallery-item.load-more')
                if (!btn) return
                this.loadMoreMessageGallery(btn)
            })
        }
    },
    methods: {
        getAskModal(id) {
            this.$modal.show('productAskModal')
            setTimeout(() => {
                document.querySelector('input[name=msgParentId]').value = id
            }, 300)
        },
        loadMoreMessageGallery(btn) {
            axios
                .get('/api/reviewGallery.json', {
                    page: this.msgGallery.page + 1,
                })
                .then((response) => {
                    if (response.data.data.length > 0) {
                        this.msgGallery.page = this.msgGallery + 1
                        this.$refs.msgGallery.insertAdjacentHTML('beforeend', response.data.data)
                        btn.remove()
                        this.msgGallery.total = this.msgGallery.total + response.data.items
                        if (this.msgGallery.total < response.data.total) {
                            this.$refs.msgGallery.insertAdjacentHTML('beforeend', btn.outerHTML)
                        }
                    }
                })
                .catch((error) => console.log(error))
        },
    },
}
