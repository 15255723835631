import axios from 'axios'
import { validateName, validateDate, validateEmail, validatePhone, validateRequired, useShowErrors } from '../../forms/formHelpers'

export default {
    name: 'Account',
    data() {
        return {
            formData: {
                user: {
                    actions: false,
                    surname: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'surname',
                        validate: true,
                        type: 'input',
                    },
                    name: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'name',
                        validate: true,
                        type: 'input',
                    },
                    patronymic: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'patronymic',
                        validate: true,
                        type: 'input',
                    },
                    birthday: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'birthday',
                        validate: true,
                        type: 'input',
                    },
                    gender: {
                        value: 1,
                        error: false,
                        showError: false,
                        type: 'select',
                    },
                    lang: {
                        value: '',
                        error: false,
                        showError: false,
                        type: 'select',
                    },
                },
                contacts: {
                    actions: false,
                    phone: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'phone',
                        validate: true,
                        type: 'input',
                    },
                    email: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'email',
                        validate: true,
                        type: 'input',
                    },
                },
                delivery: {
                    actions: false,
                },
                legal_entity: {
                    actions: false,
                    active: {
                        value: false,
                        type: 'checkbox',
                    },
                    egrpou: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'egrpou',
                        validate: true,
                        type: 'input',
                    },
                    company: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'company',
                        validate: true,
                        type: 'input',
                    },
                    email: {
                        value: '',
                        error: false,
                        showError: false,
                        rule: 'legal_entity_email',
                        validate: true,
                        type: 'input',
                    },
                },
                delete: {
                    actions: false,
                    delete: {
                        value: false,
                        type: 'checkbox',
                    },
                    comment: {
                        value: '',
                        type: 'input',
                    },
                },
            },
        }
    },
    mounted() {
        document.body.addEventListener('click', (e) => {
            if (!e.target.closest('.autocmplete-item')) {
                document.querySelectorAll('.autocomplete-list').forEach((list) => {
                    list.innerHTML = ``
                })
            }
        })

        this.initInputs()
        this.initDelivery()
        this.initLegalEntity()
    },
    methods: {
        initInputs() {
            const models = {
                user: ['name', 'surname', 'patronymic', 'birthday'],
                contacts: ['phone', 'email'],
                legal_entity: ['egrpou', 'company', 'email'],
            }
            for (let model in models) {
                models[model].forEach((name) => {
                    const input = document.querySelector(`[name=${name}]`)
                    if (input) {
                        this.formData[model][name].value = input.value
                    }
                })
            }
        },
        initLegalEntity() {
            const is_legal_entity = document.querySelector('[name=is_legal_entity]')
            if (is_legal_entity) {
                this.formData.legal_entity.active.value = is_legal_entity.hasAttribute('checked')
            }
        },
        initDelivery() {
            const deliveris = document.querySelectorAll('[name=delivery]:checked')
            if (deliveris.length > 0) {
                deliveris.forEach((delivery) => {
                    const inputs = delivery.closest('.account-delivery-row').querySelectorAll('input')
                    let data = {
                        id: {
                            value: '',
                        },
                        deep: false,
                        active: true,
                    }
                    if (inputs.length > 0) {
                        inputs.forEach((input) => {
                            if (input.name !== delivery.name) {
                                data[input.name] = {
                                    value: input.value,
                                    validate: true,
                                    rule: input.name,
                                    error: false,
                                    showError: false,
                                }
                            }
                        })
                    }

                    data.id.value = delivery.value
                    data.deep = true
                    this.$set(this.formData.delivery, delivery.value, data)
                })
            }
        },
        validate(e, rule) {
            const rules = {
                name: () => validateName(this, this.formData.user.name),
                surname: () => validateName(this, this.formData.user.surname),
                patronymic: () => validateName(this, this.formData.user.patronymic),
                birthday: () => validateDate(this, this.formData.user.birthday),
                phone: () => validatePhone(this, this.formData.contacts.phone),
                email: () => validateEmail(this, this.formData.contacts.email),
                egrpou: () => validateRequired(this, this.formData.legal_entity.egrpou),
                company: () => validateRequired(this, this.formData.legal_entity.company),
                legal_entity_email: () => validateEmail(this, this.formData.legal_entity.email),

                city: () => {
                    for (let k in this.formData.delivery) {
                        if (k === 'actions') continue
                        if (this.formData.delivery[k].hasOwnProperty('city')) {
                            validateRequired(this, this.formData.delivery[k].city)
                        }
                    }
                },
                street: () => {
                    for (let k in this.formData.delivery) {
                        if (k === 'actions') continue
                        if (this.formData.delivery[k].hasOwnProperty('street')) {
                            validateRequired(this, this.formData.delivery[k].street)
                        }
                    }
                },
                building: () => {
                    for (let k in this.formData.delivery) {
                        if (k === 'actions') continue
                        if (this.formData.delivery[k].hasOwnProperty('building')) {
                            validateRequired(this, this.formData.delivery[k].building)
                        }
                    }
                },
                room: () => {
                    for (let k in this.formData.delivery) {
                        if (k === 'actions') continue
                        if (this.formData.delivery[k].hasOwnProperty('room')) {
                            validateRequired(this, this.formData.delivery[k].room)
                        }
                    }
                },
                warehouse: () => {
                    for (let k in this.formData.delivery) {
                        if (k === 'actions') continue
                        if (this.formData.delivery[k].hasOwnProperty('warehouse')) {
                            validateRequired(this, this.formData.delivery[k].warehouse)
                        }
                    }
                },
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        onInput(e) {
            this.validate(e, e.target.name)
        },
        updateInputModel(data) {
            data.input.value = data.e.target.value
        },
        onChange(e) {
            this.showErrors(e)
        },
        comboChange() {
            this.formData.user.actions = true
        },
        autocomplete(e) {
            e.target.classList.add('loading')
            this.debounce(() => this.getData(e), e.target.getAttribute('data-debounce') || 1000)
        },
        debounce(func, timeout) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                func()
            }, timeout)
        },
        getData(e) {
            if (e.target.value.length === 0) {
                e.target.closest('.input-control').querySelector('.autocomplete-list').innerHTML = ``
                e.target.classList.remove('loading')
                return
            }
            const input = e.target
            const url = input.getAttribute('data-url')
            const params = {}
            params[input.name] = e.target.value

            axios
                .get(url, {
                    params: params,
                })
                .then((response) => {
                    e.target.classList.remove('loading')
                    this.updateList(response.data.data, e.target.value, e)
                })
                .catch((err) => {
                    e.target.classList.remove('loading')
                    console.error(err)
                })
        },
        updateList(rows, val = '', e) {
            let html = ''
            if (rows.length > 0) {
                const regex = new RegExp(val, 'gi')
                rows.forEach((row) => {
                    let display = row.value
                    const text = row.value
                    if (text.match(regex) === null) {
                        display = display
                    } else {
                        let tmp = text.split(' - ')
                        tmp[0] = tmp[0].replace(regex, `<b>${tmp[0].match(regex)[0]}</b>`)
                        display = tmp.join(' - ')
                    }
                    html += `<div class="autocomplete-item" data-id="${row.id}" data-name="${row.name}" data-value="${row.value}">${display}</div>`
                })
                const list = e.target.closest('.input-control').querySelector('.autocomplete-list')
                list.innerHTML = html
            }
        },
        pickAutocomplete(e) {
            if (e.target.classList.contains('autocomplete-item')) {
                const parent = e.target.closest('.form-group')
                parent.querySelector('input').value = e.target.getAttribute('data-value')
                parent.querySelector('.autocomplete-list').innerHTML = ``
            }
        },
        changeDelivery(e) {
            this.formData.delivery.actions = true

            const parent = e.e.target.closest('.account-delivery-row')
            const expand = parent.querySelector('.expand')
            const expandBody = expand.querySelector('.expand-body')
            const expandInner = expand.querySelector('.expand-inner')

            if (e.e.target.checked) {
                const inputs = e.e.target.closest('.account-delivery-row').querySelectorAll('input')

                if (inputs.length > 0) {
                    inputs.forEach((input) => {
                        if (input.name !== e.e.target.name) {
                            e.data[input.name] = {
                                value: input.value,
                                validate: true,
                                rule: input.name,
                                error: false,
                                showError: false,
                            }
                        }
                    })
                }
                e.data.id = { value: e.data.id }
                e.data.active = true
                e.data.deep = true
                this.$set(this.formData.delivery, e.data.id.value, e.data)

                setTimeout(() => {
                    expand.classList.add('active')
                    expandBody.style.transition = `all .3s ease 0s`
                    expandBody.style.height = `${expandInner.scrollHeight}px`
                    setTimeout(() => {
                        expandBody.classList.add('active')
                        expandBody.style.transition = null
                        expandBody.style.height = null
                    }, 300)
                })
            } else {
                setTimeout(() => {
                    expand.classList.remove('active')
                    expandBody.style.transition = `all .3s ease 0s`
                    expandBody.style.height = `${expandInner.scrollHeight}px`
                    setTimeout(() => {
                        expandBody.style.height = `0px`
                    }, 100)
                    expandBody.classList.remove('active')
                    setTimeout(() => {
                        expandBody.style.transition = null
                        expandBody.style.height = null
                        this.formData.delivery[e.data.id].active = false
                        for (let k in this.formData.delivery[e.data.id]) {
                            if (this.formData.delivery[e.data.id][k].hasOwnProperty('validate')) {
                                this.formData.delivery[e.data.id][k].validate = false
                            }
                        }
                    }, 300)
                })
            }
        },
        deliveryInput(e) {
            this.formData.delivery.actions = true
            this.formData.delivery[e.id][e.e.target.name].value = e.e.target.value
        },
        dataReset(model, url) {
            this.$Loading.start('main')
            axios
                .get(url)
                .then((response) => {
                    const data = response.data.data
                    for (let k in data) {
                        if (model === 'legal_entity') {
                            this.resetLegalEntity(model, k, data)
                        } else if (model === 'delivery') {
                            this.resetDelivery(model, k, data)
                        } else if (typeof this.formData[model][k] !== 'undefined') {
                            this.formData[model][k].value = data[k]
                            if (this.formData[model][k].type === 'select') {
                                this.$refs[model].$refs[k].setValue(data[k])
                            }
                        }
                    }
                    this.formData[model].actions = false

                    setTimeout(() => {
                        this.$Loading.finish('main')
                    }, 300)
                })
                .catch((err) => {
                    this.$Loading.finish('main')
                    console.error(err)
                })
        },
        resetLegalEntity(model, k, data) {
            if (k === 'legal_entity') {
                this.formData[model].active.value = data[k].active
                this.formData[model].egrpou.value = data[k].egrpou
                this.formData[model].company.value = data[k].company
                this.formData[model].email.value = data[k].email
            }
        },
        resetDelivery(model, k, data) {
            for (let k in this.formData.delivery) {
                if (k === 'actions') continue
                const checkbox = document.querySelector(`#${k}`)
                checkbox.checked = false
                checkbox.dispatchEvent(new Event('change'))
            }
            if (k === 'delivery') {
                for (let i = 0; i < data[k].length; i++) {
                    let row = {
                        id: {
                            value: '',
                        },
                        active: true,
                    }
                    row.id.value = data[k][i].id
                    for (let prop in data[k][i]) {
                        if (prop === 'id') continue
                        row[prop] = {
                            value: data[k][i][prop],
                            validate: true,
                            rule: prop,
                            error: false,
                            showError: false,
                        }
                    }

                    const checkbox = document.querySelector(`#${row.id.value}`)
                    checkbox.checked = true
                    checkbox.dispatchEvent(new Event('change'))
                    this.$set(this.formData.delivery, 'np', row)
                }
            }
        },
        onSubmit(e, model) {
            e.preventDefault()

            // console.log(this.formData.user.birthday.value)
            let err = []
            for (let k in this.formData) {
                const row = this.formData[k]
                if (k === model) {
                    for (let prop in row) {
                        if (row[prop] !== null && row[prop].toString() === '[object Object]') {
                            if (row[prop].hasOwnProperty('rule') && row[prop].validate) {
                                this.validate(null, row[prop].rule)
                                useShowErrors(this, row[prop])
                                row[prop].error && err.push(true)
                            }
                            if (row[prop].hasOwnProperty('deep')) {
                                for (let key in row[prop]) {
                                    if (row[prop][key].hasOwnProperty('rule') && row[prop][key].validate) {
                                        this.validate(null, row[prop][key].rule)
                                        useShowErrors(this, row[prop][key])
                                        row[prop][key].error && err.push(true)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (err.includes(true)) return

            this.$Loading.start('main')

            let data

            if (model === 'delivery') {
                data = this.prepareDelivery(model)
            } else {
                data = this.prepareData(model)
            }

            const form = e.target
            axios({
                url: form.getAttribute('action'),
                method: form.getAttribute('method'),
                data: data,
            })
                .then((response) => {
                    setTimeout(() => {
                        this.$Loading.finish('main')
                    }, 300)
                    if (response.data.confirmPhone) {
                        this.$modal.show('checkPhone')
                        setTimeout(() => {
                            document.querySelector('.current-phone').innerText = data.phone
                        }, 300)
                        return
                    }
                    setTimeout(() => {
                        this.$notify({
                            group: 'default',
                            text: response.data.message,
                        })
                    }, 300)
                    for (let k in this.formData) {
                        this.formData[k].actions = false
                    }
                    if (response.data.deleteAccount) {
                        setTimeout(() => {
                            document.location.href = '/'
                        }, 1300)
                    }
                })
                .catch((err) => {
                    this.$Loading.finish('main')
                    console.error(err)
                })
        },
        prepareData(model) {
            let data = {}
            for (let k in this.formData[model]) {
                if (this.formData[model][k].hasOwnProperty('value')) {
                    data[k] = this.formData[model][k].value || ''
                }
            }
            return data
        },
        prepareDelivery(model) {
            let data = []
            for (let k in this.formData.delivery) {
                if (k === 'actions') continue
                let dataRow = {}
                dataRow.active = this.formData.delivery[k].active
                for (let row in this.formData.delivery[k]) {
                    if (this.formData.delivery[k][row].hasOwnProperty('value')) {
                        dataRow[row] = this.formData.delivery[k][row].value
                    }
                }
                data.push(dataRow)

                if (!dataRow.active) {
                    this.$delete(this.formData.delivery, k)
                }
            }
            return data
        },
    },
}
