import { validateRequired, useShowErrors } from '../../../forms/formHelpers'

export default {
    props: {
        id: {
            type: Number,
        },
        value: {},
        model: {
            type: Object,
        },
        props: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {
                city: {
                    value: '',
                    error: false,
                    showError: false,
                    name: 'city',
                },
                street: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'street',
                    validate: true,
                },
                building: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'building',
                    validate: true,
                },
                room: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'room',
                    validate: true,
                },
            },
        }
    },
    watch: {
        value(val) {
            if (val.delivery !== this.id) {
                for (let k in this.formData) {
                    if (!this.formData[k]) return
                    if (this.formData[k].hasOwnProperty('error')) {
                        this.formData[k].showError = false
                    }
                }
            }
        },
    },
    methods: {
        onChange() {
            this.formData.city = this.props.city
            this.$emit('input', this.formData)
        },
        validate(rule) {
            const rules = {
                street: () => validateRequired(this, this.formData.street),
                building: () => validateRequired(this, this.formData.building),
                room: () => validateRequired(this, this.formData.room),
                warehouse: () => validateRequired(this, this.formData.warehouse),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
    },
}
