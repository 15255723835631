import axios from 'axios'
import { validateName, validateEmail, validatePhone, validateRequired, useShowErrors } from './forms/formHelpers'

export default {
    data() {
        return {
            loading: false,
            form: true,
            check: false,
            timer: null,
            cooldownMsg: false,
            pincode: {
                value: ['', '', '', ''],
            },
            pincodeLoading: false,
            pincodeError: true,
            pincodeShowError: false,
            formData: {
                loading: false,
                phone: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'phone',
                    validate: true,
                },
            },
        }
    },
    watch: {
        pincode: {
            handler(val) {
                if (!val.value.includes('')) {
                    this.$refs.checkform.dispatchEvent(new Event('submit'))
                }
            },
            deep: true,
        },
    },
    mounted() {
        // this.$refs.phoneform.querySelector('[name=phone]').addEventListener('input', (e) => {
        //     this.validate(e, 'phone')
        // })
    },
    methods: {
        validate(e, rule) {
            const rules = {
                phone: () => validatePhone(this, this.formData.phone, 16),
            }

            rules.hasOwnProperty(rule) && rules[rule]()

            console.log(this.formData.phone.value.length)

            if (this.formData.phone.value.length === 19 && !this.formData.phone.error && !this.loading) {
                // this.$refs.phoneform.dispatchEvent(new Event('submit'))
                this.sendPhone(this.$refs.phoneform)
            }
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        onFocus(e) {
            e.currentTarget.closest('.input-control').classList.add('focus')
        },
        onBlur(e) {
            e.currentTarget.closest('.input-control').classList.remove('focus')
        },
        back() {
            this.form = true
            this.check = false
            this.formData.phone.value = ''
            this.$nextTick(() => {
                this.$refs.phone.focus()
            })
        },
        nextNum(num) {
            const inputs = this.$refs.checkform.querySelectorAll('.input')
            if (inputs[num - 1].value !== '') return
            inputs[num - 1].focus()
        },
        updateInput(e, i) {
            e.target.value = e.target.value.slice(0, 1)
            let val = this.pincode.value
            val[i] = e.target.value
            this.$set(this.pincode.value, val)
            this.$forceUpdate()
        },
        sendPhone(e) {
            this.loading = true
            if (typeof e.preventDefault === 'function') {
                e.preventDefault()
            }

            validatePhone(this, this.formData.phone, 16)

            if (this.formData.phone.error || this.formData.phone.value.length < 19) {
                this.showErrors(this.formData.phone)
                this.loading = false
                return
            }

            this.formData.loading = true

            axios({
                url: this.$refs.phoneform.action,
                method: this.$refs.phoneform.method,
                data: {
                    phone: this.formData.phone.value,
                },
            })
                .then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.formData.loading = false
                            this.form = false
                            this.check = true
                            this.$nextTick(() => {
                                this.loading = false
                                this.$refs.checkform.querySelectorAll('.input')[0].focus()
                            })
                        }, 300)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.formData.loading = false
                    console.error(err)
                })
        },
        phoneFocus(e) {
            if (e.target.value.length === 0) {
                e.target.value = '+38'
            }
        },
        countdown(duration) {
            let timer = duration,
                minutes,
                seconds

            this.cooldownMsg = true
            let m, s
            m = parseInt(timer / 60, 10)
            s = parseInt(timer % 60, 10)
            m = m < 10 ? '0' + m : m
            s = s < 10 ? '0' + s : s

            this.timer = m + ':' + s

            const interval = setInterval(() => {
                minutes = parseInt(timer / 60, 10)
                seconds = parseInt(timer % 60, 10)

                minutes = minutes < 10 ? '0' + minutes : minutes
                seconds = seconds < 10 ? '0' + seconds : seconds

                this.timer = minutes + ':' + seconds

                if (--timer < 0) {
                    timer = duration
                    this.timer = null
                    this.cooldownMsg = false
                    clearInterval(interval)
                }
            }, 1000)

            if (typeof interval === 'function') {
                interval()
            }
        },
        sendCode(params) {
            axios({
                url: params.url,
                method: params.method,
            })
                .then((response) => {
                    if (response.data.success) {
                        this.countdown(response.data.seconds)
                    }
                })
                .catch((err) => console.error(err))
        },
        sendPincode(e) {
            e.preventDefault()

            if (this.pincode.value.join('').length !== 4) return
            this.pincodeLoading = true

            axios({
                url: e.target.getAttribute('action'),
                method: e.target.getAttribute('method'),
                data: {
                    code: this.pincode.value.join(''),
                },
            })
                .then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.pincodeLoading = false
                            this.pincodeError = false
                            this.pincodeShowError = false
                            document.location.href = response.data.location
                        }, 300)
                    } else {
                        setTimeout(() => {
                            this.pincodeLoading = false
                            this.pincodeError = true
                            this.pincodeShowError = true
                        }, 300)
                    }
                })
                .catch((err) => {
                    this.pincodeLoading = false
                    console.error(err)
                })
        },
    },
}
