import { formDisabledToggle, useShowErrors, validateEmail } from '../../forms/formHelpers'
import axios from 'axios'

export default {
    data() {
        return {
            formData: {
                email: {
                    value: '',
                    error: true,
                    showError: false,
                },
            },
        }
    },
    methods: {
        validate(e, rule) {
            const rules = {
                email: () => validateEmail(this, this.formData.email),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        submit(e) {
            e.preventDefault()

            if (this.formData.email.error) return
            this.$Loading.start('productNotify')
            formDisabledToggle(e.target, true)
            axios({
                url: e.target.getAttribute('action'),
                method: e.target.getAttribute('method'),
                data: {
                    email: this.formData.email.value,
                },
            }).then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.$Loading.finish('productQstModal')
                            formDisabledToggle(e.target, false)
                            e.target.reset()
                            this.$notify({
                                group: 'default',
                                text: response.data.message,
                            })
                        }, 300)
                    }
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.$Loading.finish('productNotify')
                        formDisabledToggle(e.target, false)
                        console.log(error)
                    }, 300)
                })
        },
    },
}
