import Vue from 'vue'

import Swiper from 'swiper/bundle'
import {
    Home,
    Catalog,
    Product,
    Categories,
    Checkout,
    OrderSuccess,
    Info,
    NotFound,
    Sales,
    Sale,
    Account,
    Orders,
    Subscribes,
    AccountReviews,
    AccountCatalog,
    Blog,
    PageArticle,
    Brands,
} from './components/pages'
import Search from './components/search'
import Subscribe from './components/subscribe'
import Minicart from './components/minicart'
import Auth from './components/auth'
import CheckOnline from './components/checkOnline'
import BurgerMenu from './components/menu'
import Notifications from 'vue-notification'
import ChangeCity from './components/changeCity'
import CheckPhone from './components/checkPhoneModal'
import OrderHistory from './components/orderHistory'
import OrderCancel from './components/orderCancel'

Vue.component('subscribe', Subscribe)
Vue.use(Notifications)

export default {
    name: 'App',
    components: {
        Search,
        Home,
        Catalog,
        Product,
        Categories,
        Checkout,
        OrderSuccess,
        Info,
        NotFound,
        Sales,
        Sale,
        Auth,
        Account,
        Orders,
        Blog,
        Brands,
        PageArticle,
        Subscribes,
        AccountReviews,
        AccountCatalog,
        Minicart,
        CheckOnline,
        BurgerMenu,
        ChangeCity,
        CheckPhone,
        OrderHistory,
        OrderCancel,
    },
    data() {
        return {
            textMore: '',
            scroll: window.pageYOffset,
            menu: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            this.floatHeader('hide')
        })

        const sab = parseInt(
            getComputedStyle(document.documentElement)
                .getPropertyValue('--sab')
                .replace('px', '')
        )
        if (sab > 0) {
            this.$refs.bottomBar.classList.add('sab')
        }
    },
    methods: {
        menuToggle() {
            this.menu = !this.menu
        },
        onChangeCity(data) {
            const currentCity = this.$refs.currentCity
            currentCity.innerText = data.value
            currentCity.setAttribute('data-id', data.id)
            const menuCity = this.$refs.menu.$refs.currentCity
            menuCity.innerText = data.value
            menuCity.setAttribute('data-id', data.id)
            this.$modal.hide('changeCities')
        },
        floatHeader(hideClass) {
            if (window.pageYOffset < 150) {
                this.$refs.header.classList.remove(hideClass)
            } else {
                if (window.pageYOffset > this.scroll) {
                    this.$refs.header.classList.add(hideClass)
                    this.scroll = window.pageYOffset
                }
                if (window.pageYOffset < this.scroll) {
                    this.$refs.header.classList.remove(hideClass)
                    this.scroll = window.pageYOffset
                }
            }
        },
        cartProductsSliderInit() {
            new Swiper('.cart-products-slider', {
                spaceBetween: 0,
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    320: {
                        slidesPerView: 2,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 4,
                        },
                    },
                    500: {
                        slidesPerView: 3,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 4,
                        },
                    },
                    660: {
                        slidesPerView: 3,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 4,
                        },
                    },
                    768: {
                        slidesPerView: 4,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 4,
                        },
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    1360: {
                        slidesPerView: 4,
                    },
                },
            })
        },
    },
}
