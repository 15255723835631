import axios from 'axios'

const pagin = ($config) => {
    const config = {
        wrapper: null,
        container: null,
        btn: null,
        limit: 0,
        url: null,
        onStart: null,
        onLoad: null,
        onAppendResults: null,
        returnHTML: false,
        ...$config,
    }

    const wrappers = document.querySelector(config.wrapper)
    if (!wrappers) return

    const state = {
        wrapper: null,
        container: null,
        btnMore: null,
        page: 1,
        total: 0,
        limit: config.limit,
    }

    // wrappers.forEach((wrap) => {
    const wrap = wrappers
    const container = wrap.querySelector(config.container)
    let btn = wrap.querySelector(config.btn)

    if (!config.url) {
        if (wrap.getAttribute('data-url')) {
            config.url = wrap.getAttribute('data-url')
        }
    }

    if (!container || !btn || !config.url) return

    state.wrapper = wrap
    state.container = container
    state.btnMore = btn
    state.total = state.container.children.length
    toggleBtnMore(state, state.total, state.limit + 1)

    btn.parentNode.replaceChild(btn.cloneNode(true), btn)

    state.btnMore = wrap.querySelector(config.btn)

    state.btnMore.addEventListener('click', (e) => {
        loadMore(e, config, state)
    })

    return state
    // })
}

const loadMore = (e, config, state) => {
    e.preventDefault()

    typeof config.onStart === 'function' && config.onStart(state)
    axios
        .get(config.url, {
            params: {
                page: (state.page = state.page + 1),
                limit: state.limit,
            },
        })
        .then((response) => {
            if (config.returnHTML) {
                typeof config.onAppendResults === 'function' && config.onAppendResults(response, state)
            } else {
                appendResults(response.data.data, state)
            }
            updateTotal(response.data, state)
            typeof config.onLoad === 'function' && config.onLoad(state, response)
        })
}

const appendResults = (data, state) => {
    if (!data) return
    state.container.insertAdjacentHTML('beforeend', data)
}

const updateTotal = (data, state) => {
    if (!data) return
    state.total = state.total + data.items
    toggleBtnMore(state, state.total, data.total)
}

const toggleBtnMore = (state, current, max) => {
    if (current >= max) {
        state.btnMore.style.display = 'none'
    }
}

export { pagin }
