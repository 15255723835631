import VueSlider from 'vue-slider-component'

export default {
    name: 'filterSlider',
    components: {
        VueSlider,
    },
    methods: {
        changeSlider(data) {
            this.$refs.filterSliderMin.$refs.input.value = data[0]
            this.$refs.filterSliderMax.$refs.input.value = data[1]
        },
        dragEnd() {
            this.$refs.filterSliderMin.$refs.input.dispatchEvent(new Event('change'))
            this.$refs.filterSliderMax.$refs.input.dispatchEvent(new Event('change'))
        }
    }
}