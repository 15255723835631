export default {
    name: 'Menu',
    props: {
        active: {
            type: Boolean,
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}