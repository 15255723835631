const anchorScroll = (parent, rootMargin) => {
    if (!rootMargin) {
        rootMargin = 0
    }

    const root = document.querySelector(parent)
    var linkNav = root.querySelectorAll('[href^="#"]')
    var V = 0.15
    for (var i = 0; i < linkNav.length; i++) {
        linkNav[i].addEventListener(
            'click',
            function(e) {
                e.preventDefault()
                let w = window.pageYOffset
                let hash = this.href.replace(/[^#]*(.*)/, '$1')
                if (hash.length < 2 || !document.querySelector(hash)) return
                let t = document.querySelector(hash).getBoundingClientRect().top
                let start = null
                requestAnimationFrame(step)
                function step(time) {
                    if (start === null) start = time
                    var progress = time - start,
                        r = t < 0 ? Math.max(w - progress / V, w + t) : Math.min(w + progress / V, w + t)

                    window.scrollTo(0, r)
                    if (r != w + t) {
                        requestAnimationFrame(step)
                    } else {
                        location.hash = hash
                        window.scrollTo(0, w + t - rootMargin)
                    }
                }
            },
            false
        )
    }
}

export { anchorScroll }
