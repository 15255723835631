import axios from 'axios'
import { validateName, validateEmail, validatePhone, validateRequired, useShowErrors } from '../../forms/formHelpers'
import { phonePrefix } from '../../utils'

import DeliveryNp from './deliverys/deliveryNP'
import DeliveryNpc from './deliverys/deliveryNPC'

import Payment from './payments/payment'
import PaymentCashless from './payments/paymentCashless'
import PaymentCredit from './payments/paymentCredit'

import AnotherRecipient from './anotherRecipient'

export default {
    name: 'Checkout',
    components: {
        DeliveryNp,
        DeliveryNpc,
        Payment,
        PaymentCashless,
        PaymentCredit,
        AnotherRecipient,
    },
    data() {
        return {
            formData: {
                receiver: {
                    phone: {
                        value: '',
                        error: true,
                        showError: false,
                        rule: 'receiverPhone',
                        validate: true,
                    },
                    email: {
                        value: '',
                        error: true,
                        showError: false,
                        rule: 'receiverEmail',
                        validate: true,
                    },
                    name: {
                        value: '',
                        error: true,
                        showError: false,
                        rule: 'receiverName',
                        validate: true,
                    },
                    patronymic: {
                        value: '',
                        error: true,
                        showError: false,
                        rule: 'receiverPatronymic',
                        validate: true,
                    },
                    surname: {
                        value: '',
                        error: true,
                        showError: false,
                        rule: 'receiverSurname',
                        validate: true,
                    },
                },
                delivery: {},
                payment: {},
                additional: {
                    comment: {
                        value: '',
                        error: false,
                        showError: false,
                    },
                    callback: false,
                },
                anotherRecipient: {},
                promocode: {
                    value: '',
                    error: true,
                    showError: false,
                    success: true,
                    rule: 'promocode',
                },
            },
            city: {
                value: '',
                error: false,
                showError: false,
                name: 'city',
            },
            steps: [],
            userSuccess: true,
        }
    },
    mounted() {
        this.steps = this.$refs.stepper.querySelectorAll('.step')
        this.steps.forEach((step, i) => {
            if (step.querySelector('.step-next-btn')) {
                step.querySelector('.step-next-btn').addEventListener('click', (e) => {
                    this.setStep(i)
                })
            }
        })
        const selected = this.$refs.delivery_city.$el.querySelectorAll('.select-item')[0]
        if (selected) {
            this.city.value = selected.getAttribute('data-value')
            this.$refs.delivery_city.change()
        }

        phonePrefix(this.formData.receiver.phone, this.formData.receiver.phone.value, '+38 ')
    },
    computed: {
        receiver() {
            const f = []
            for (let k in this.formData.receiver) {
                if (this.formData.receiver[k].hasOwnProperty('error')) {
                    f.push(this.formData.receiver[k].error)
                }
            }
            return f.includes(true) ? true : false
        },
        delivery() {
            const f = []
            for (let k in this.formData.delivery) {
                if (this.formData.delivery[k].hasOwnProperty('error')) {
                    f.push(this.formData.delivery[k].error)
                }
            }
            return f.includes(true) ? true : false
        },
        payment() {
            const f = []
            for (let k in this.formData.payment) {
                if (this.formData.payment[k].hasOwnProperty('error')) {
                    f.push(this.formData.payment[k].error)
                }
            }
            return f.includes(true) ? true : false
        },
    },
    methods: {
        phonePrefix(model) {
            phonePrefix(model, model.value, '+38 ')
        },
        validate(e, rule) {
            const rules = {
                receiverName: () => validateName(this, this.formData.receiver.name),
                receiverSurname: () => validateName(this, this.formData.receiver.surname),
                receiverPatronymic: () => validateName(this, this.formData.receiver.patronymic),
                receiverEmail: () => validateEmail(this, this.formData.receiver.email),
                receiverPhone: () => validatePhone(this, this.formData.receiver.phone),
                street: () => validateRequired(this, this.formData.delivery.street),
                building: () => validateRequired(this, this.formData.delivery.building),
                room: () => validateRequired(this, this.formData.delivery.room),
                warehouse: () => validateRequired(this, this.formData.delivery.warehouse),
                egrpou: () => validateRequired(this, this.formData.payment.egrpou),
                company: () => validateRequired(this, this.formData.payment.company),
                anotherRecipientName: () => validateName(this, this.formData.anotherRecipient.name),
                anotherRecipientPatronymic: () => validateName(this, this.formData.anotherRecipient.patronymic),
                anotherRecipientSurname: () => validateName(this, this.formData.anotherRecipient.surname),
                anotherRecipientPhone: () => validatePhone(this, this.formData.anotherRecipient.phone),
                promocode: () => validateRequired(this, this.formData.promocode),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        submit(e) {
            e.preventDefault()
            let err = []
            for (let k in this.formData) {
                const row = this.formData[k]
                for (let prop in row) {
                    if (row[prop] !== null && row[prop].toString() === '[object Object]') {
                        if (row[prop].hasOwnProperty('rule') && row[prop].validate) {
                            this.validate(null, row[prop].rule)
                            useShowErrors(this, row[prop])
                            row[prop].error && err.push(true)
                        }
                    }
                }
            }
            if (err.includes(true)) return

            const form = e.target
            axios({
                url: form.getAttribute('action'),
                method: form.getAttribute('method'),
                data: this.formData,
            })
                .then((response) => {
                    if (response.data.location) {
                        document.location.href = response.data.location
                    }
                })
                .catch((err) => console.error(err))
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        setStep(i) {
            this.steps[i].classList.add('done')
            const next = this.steps[i + 1]
            const nextWrap = next.querySelector('.step-content-wrap')

            nextWrap.style.overflow = 'hidden'
            nextWrap.style.height = `${next.querySelector('.step-content').scrollHeight}px`
            next.classList.remove('disabled')

            setTimeout(() => {
                nextWrap.style.overflow = null
                nextWrap.style.height = null
            }, 300)
        },
        toggleCartProducts(e) {
            e.preventDefault()
            const self = e.currentTarget

            const products = this.$refs.cartProducts.querySelectorAll('.cart-product.hidden')
            if (!products) return

            products.forEach((product) => {
                if (product.style.display === 'none' || product.style.display === '') {
                    product.style.display = 'block'
                    self.innerText = self.getAttribute('data-hide')
                } else {
                    product.style.display = 'none'
                    self.innerText = self.getAttribute('data-show')
                }
            })
        },
        checkPromocode(url) {
            this.validate(null, 'promocode')
            if (this.formData.promocode.error) {
                this.showErrors(this.formData.promocode)
                return
            }
            this.$Loading.start('main')
            axios
                .get(url, {
                    data: {
                        promocode: this.formData.promocode.value,
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        this.formData.promocode.error = false
                        this.formData.promocode.showError = false
                        this.formData.promocode.success = true
                        this.$Loading.finish('main')
                    } else {
                        this.formData.promocode.error = true
                        this.formData.promocode.showError = true
                        this.formData.promocode.success = false
                        this.$Loading.finish('main')
                    }
                })
                .catch((err) => {
                    this.$Loading.finish('main')
                    console.error(err)
                })
        },
        checkUser(url) {
            axios
                .get(url, {
                    data: {
                        phone: this.formData.receiver.phone,
                    },
                })
                .then((response) => {
                    this.userSuccess = response.data.success
                })
                .catch((err) => console.error(err))
        },
        setCity(url) {
            axios
                .get(`${url}${this.city.value}.json`, {
                    data: {
                        id: this.city.value,
                    },
                })
                .then((response) => {
                    this.$refs.delivery_np.$refs.warehouses.updateOptions(response.data, {
                        key: 'id',
                        display: 'name',
                    })
                    if (this.formData.delivery.hasOwnProperty('warehouse')) {
                        this.formData.delivery.warehouse.value = ''
                        this.formData.delivery.warehouse.error = true
                        this.formData.delivery.warehouse.showError = false
                        this.$refs.delivery_np.$refs.warehouses.display = ''
                        this.$refs.delivery_np.descr = ''
                    }
                })
                .catch((err) => console.error(err))
        },
    },
}
