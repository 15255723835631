import { formDisabledToggle, validateName, validateEmail, useShowErrors } from '../../forms/formHelpers'

import axios from 'axios'

export default {
    data() {
        return {
            formData: {
                email: {
                    value: '',
                    error: false,
                    showError: false,
                },
                name: {
                    value: '',
                    error: false,
                    showError: false,
                },
                msg: {
                    value: '',
                    error: false,
                    showError: false,
                },
            },
        }
    },
    computed: {
        disabled() {
            return this.formData.email.value === '' ||
                this.formData.email.error ||
                this.formData.name.value === '' ||
                this.formData.name.error
                ? true
                : false
        },
    },
    methods: {
        validate(e, rule) {
            const rules = {
                name: () => validateName(this, this.formData.name),
                email: () => validateEmail(this, this.formData.email),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        submit(e) {
            e.preventDefault()

            if (this.formData.email.error) return
            this.$Loading.start('productAskModal')
            formDisabledToggle(e.target, true)

            axios({
                url: e.target.getAttribute('action'),
                method: e.target.getAttribute('method'),
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: {
                    name: this.formData.name.value,
                    email: this.formData.email.value,
                    msg: this.formData.msg.value,
                },
            }).then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.$Loading.finish('productAskModal')
                            formDisabledToggle(e.target, false)
                            e.target.reset()
                            this.$notify({
                                group: 'default',
                                text: response.data.message,
                            })
                        }, 300)
                    }
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.$Loading.finish('productAskModal')
                        formDisabledToggle(e.target, false)
                        console.log(error)
                    }, 300)
                })
        },
    },
}
