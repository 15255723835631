import { useShowErrors, validateName, validatePhone } from '../../forms/formHelpers'
import { phonePrefix } from '../../utils'

export default {
    props: {
        id: {
            type: Number,
        },
        value: {},
        model: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {
                check: false,
                name: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'anotherRecipientName',
                    validate: true,
                },
                patronymic: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'anotherRecipientPatronymic',
                    validate: true,
                },
                surname: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'anotherRecipientSurname',
                    validate: true,
                },
                phone: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'anotherRecipientPhone',
                    validate: true,
                },
            },
        }
    },
    mounted() {
        phonePrefix(this.formData.phone, this.formData.phone.value, '+38 ')
    },
    methods: {
        phonePrefix(model) {
            phonePrefix(model, model.value, '+38 ')
        },
        onChange() {
            let data = this.formData
            if (!data.check) {
                data = {}
            }
            this.$emit('input', data)
        },
        validate(rule) {
            const rules = {
                anotherRecipientName: () => validateName(this, this.formData.name),
                anotherRecipientPatronymic: () => validateName(this, this.formData.patronymic),
                anotherRecipientSurname: () => validateName(this, this.formData.surname),
                anotherRecipientPhone: () => validatePhone(this, this.formData.phone),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
    },
}
