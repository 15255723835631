import Swiper from 'swiper/bundle'

const productsSliderInit = () => {
    new Swiper('.products-slider', {
        spaceBetween: 0,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            500: {
                slidesPerView: 3,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            660: {
                slidesPerView: 3,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            768: {
                slidesPerView: 4,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            1024: {
                slidesPerView: 4,
            },
            1360: {
                slidesPerView: 5,
            },
        },
    })
}

const cardsSliderInit = () => {
    new Swiper('.cards-slider', {
        slidesPerView: 4,
        spaceBetween: 0,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1.15,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            500: {
                slidesPerView: 2.2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            660: {
                slidesPerView: 2.5,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            768: {
                slidesPerView: 2.3,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            1024: {
                slidesPerView: 3,
            },
            1360: {
                slidesPerView: 4,
            },
        },
    })
}

const categoriesSliderInit = () => {
    const sliders = document.querySelectorAll('.slider-categories')
    if (sliders.length === 0) return

    sliders.forEach(slider => {
        const parent = slider.closest('.slider-categories-wrap')
        const btnPrev = parent.querySelector('.swiper-button-prev')
        const btnNext = parent.querySelector('.swiper-button-next')

        new Swiper('.slider-categories', {
            slidesPerView: 6,
            spaceBetween: 16,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: btnNext,
                prevEl: btnPrev,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.5,
                },
                768: {
                    slidesPerView: 3.35,
                },
                1024: {
                    slidesPerView: 4.25,
                },
                1360: {
                    slidesPerView: 5.5,
                },
            },
        })
    })
}

const brandsSwiperInit = (ctx) => {
    if (typeof ctx.$refs.brandsSlider === 'undefined') return
    const items = ctx.$refs.brandsSlider.querySelectorAll('.brand-cart').length
    let isColumns = true
    if (items < 12 && document.documentElement.clientWidth > 768) {
        ctx.$refs.brandsSlider.classList.add('small')
        isColumns = false
    }

    new Swiper('.brand-slider', {
        spaceBetween: 24,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                slidesPerColumn: 3,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            500: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            660: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            768: {
                slidesPerView: 5,
                slidesPerColumn: 2,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                },
            },
            1024: {
                slidesPerView: 5,
                slidesPerColumn: isColumns ? 2 : null,
            },
            1360: {
                slidesPerView: 6,
                slidesPerColumn: isColumns ? 2 : null,
            },
        },
    })
}

export { productsSliderInit, cardsSliderInit, brandsSwiperInit, categoriesSliderInit }
