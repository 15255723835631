import { validateRequired, useShowErrors } from '../../../forms/formHelpers'

export default {
    props: {
        id: {
            type: Number,
        },
        value: {},
        model: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {
                id: null,
                egrpou: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'egrpou',
                    validate: true,
                },
                company: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'company',
                    validate: true,
                },
                company_email: {
                    value: '',
                    error: true,
                    showError: false,
                    rule: 'company_email',
                    validate: true,
                },
            },
        }
    },
    watch: {
        value(val) {
            if (val.id !== this.id) {
                for (let k in this.formData) {
                    if (!this.formData[k]) return
                    if (this.formData[k].hasOwnProperty('error')) {
                        this.formData[k].showError = false
                    }
                }
            }
        },
    },
    methods: {
        onChange() {
            this.formData.id = this.id
            this.$emit('input', this.formData)
        },
        validate(rule) {
            const rules = {
                egrpou: () => validateRequired(this, this.formData.egrpou),
                company: () => validateRequired(this, this.formData.company),
                company_email: () => validateRequired(this, this.formData.company_email),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
    },
}
