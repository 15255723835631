import axios from 'axios'
import Vue from 'vue'

export default {
    data() {
        return {
            sort: {
                value: null,
                name: 'sort',
            },
            messages: {
                limit: 6,
                page: 1,
                total: 0,
            },
        }
    },
    mounted() {
        this.messages.total = this.$refs.messagesList.querySelectorAll('.msg-item').length
        this.$refs.messagesList.addEventListener('click', (e) => {
            let btn = e.target.closest('.more-btn')
            if (!btn) return
            this.loadMore(btn.parentElement)
        })
    },
    methods: {
        loadMore(btn) {
            axios
                .get('/api/qstItems.json', {
                    page: this.messages.page + 1,
                })
                .then((response) => {
                    const NewComponent = Vue.extend({
                        template: '<div>' + response.data.data + '</div>',
                    })

                    const compiled = new NewComponent().$mount()

                    this.$refs.messagesList.querySelector('.msg-list').append(compiled.$el)
                    if (response.data.data.length > 0) {
                        this.messages.page = this.messages.page + 1
                        btn.remove()
                        this.messages.total = this.messages.total + response.data.items
                        if (this.messages.total < response.data.total) {
                            this.$refs.messagesList.insertAdjacentHTML('beforeend', btn.outerHTML)
                        }
                    }
                    this.$forceUpdate()
                })
                .catch((error) => console.log(error))
        },
    },
}
