import axios from 'axios'

export default {
    name: 'OrderHistory',
    props: ['id'],
    data() {
        return {
            items: [],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$Loading.start('orderHistory')
            axios
                .get(this.$refs.orderHistory.getAttribute('data-url'), {
                    params: {
                        id: this.id,
                    },
                })
                .then((response) => {
                    this.items = response.data.data
                    setTimeout(() => {
                        this.$Loading.finish('orderHistory')
                    }, 300)
                })
                .catch((err) => {
                    this.$Loading.finish('orderHistory')
                    console.error(err)
                })
        },
    },
}
