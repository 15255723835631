<template>
    <modal
        :name="name"
        :width="width"
        :max-width="maxWidth"
        height="auto"
        :max-height="maxHeight"
        :adaptive="true"
        @before-open="beforeModalOpen"
        @before-close="beforeModalClose"
        @opened="opened"
    >
        <div :class="['modal-layout', `modal-${name}`]">
            <div class="modal-header">
                <progress-bar :name="name"></progress-bar>
                <div class="modal-title">{{ title }}</div>
                <button class="btn modal-close-btn" @click="$modal.hide(name)">
                    <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.66699 1.646L12.333 12.31M1.66699 12.31L12.333 1.646"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
            </div>
            <div class="modal-body">
                <slot v-bind:payload="payload"></slot>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        maxWidth: {
            default: 896,
        },
        width: {
            default: '90%',
        },
        maxHeight: {
            // default: 90,
        },
    },
    data() {
        return {
            scroll: window.pageYOffset,
            payload: {},
        }
    },
    methods: {
        beforeModalOpen(e) {
            this.payload = e.params
            this.scroll = window.pageYOffset
            const wrap = document.getElementById('app')
            document.querySelector('.site').style.transform = `translateY(-${window.pageYOffset}px)`
            wrap.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
            wrap.classList.add('overflow')
        },
        beforeModalClose() {
            const wrap = document.getElementById('app')
            wrap.classList.remove('overflow')
            wrap.style.paddingRight = null
            document.querySelector('.site').style.transform = null
            window.scrollTo(0, this.scroll)
        },
        opened() {
            this.$emit('opened')
        },
    },
}
</script>
