import axios from 'axios'
import { pagin } from '../../pagin'

export default {
    name: 'AccountCatalog',
    data() {
        return {
            pagin: {
                paginate: null,
                limit: 24,
            },
        }
    },
    mounted() {
        this.initPagin()
    },
    methods: {
        removeAllProducts(url, method) {
            this.$Loading.start('main')
            axios({
                url: url,
                method: method,
            })
                .then((response) => {
                    this.$refs.catalog.remove()
                    this.$refs.empty.style.removeProperty('display')

                    setTimeout(() => {
                        this.$Loading.finish('main')
                        this.$notify({
                            group: 'default',
                            text: response.data.message,
                        })
                    }, 300)
                })
                .catch((err) => {
                    this.$Loading.finish('main')
                    console.error(err)
                })
        },
        initPagin() {
            this.pagin.paginate = pagin
            return this.pagin.paginate({
                wrapper: '.account-catalog-grid',
                container: '.products-grid',
                btn: '.load-more-btn',
                limit: this.pagin.limit,
                onStart: () => {
                    this.$Loading.start('main')
                },
                onLoad: (state, response) => this.updateLoadMoreBtn(state, response),
            })
        },
        updateLoadMoreBtn(state, response) {
            if (response.data.total - state.total > state.limit) {
                state.btnMore.querySelector('span').innerText = state.limit
            } else {
                state.btnMore.querySelector('span').innerText = response.data.total - state.total
            }
            setTimeout(() => {
                this.$Loading.finish('main')
            }, 300)
        },
    },
}
