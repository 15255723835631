import { productsSliderInit, categoriesSliderInit, cardsSliderInit, brandsSwiperInit } from '../../sliders'
// import FilterSection from '../../filterSection'
import VFilter from '../../filter'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
    name: 'Catalog',
    components: {
        // FilterSection,
        VFilter,
        VueSkeletonLoader,
    },
    data() {
        return {
            textMore: '',
            loaded: false,
            currentSortby: '',
            sort: {
                value: null,
                name: 'sort',
            },
        }
    },
    mounted() {
        this.loaded = true
        cardsSliderInit()
        productsSliderInit()
        categoriesSliderInit()
        brandsSwiperInit(this)
        this.currentSortby = this.$refs.sortby.display
        if (document.documentElement.clientWidth > 768) {
            window.addEventListener('scroll', () => {
                window.pageYOffset + 1 > this.$refs.filter_header.getBoundingClientRect().top + scrollY
                    ? this.$refs.filter_header.classList.add('sticky')
                    : this.$refs.filter_header.classList.remove('sticky')
            })
        }

        document.addEventListener('DOMContentLoaded', () => {
            if (typeof this.$refs.page_content !== 'undefined') {
                if (this.$refs.page_content.offsetHeight > 110) {
                    this.$refs.page_text_more.classList.add('active')
                }
            }
        })
    },
    methods: {
        showSortby() {
            this.$refs.sortby.onFocus()
        },
        sortChange() {
            this.currentSortby = this.$refs.sortby.display
        },
        pageTextToggle(e) {
            const showText = e.target.getAttribute('data-show')
            const hideText = e.target.getAttribute('data-hide')
            if (this.textMore === '') {
                e.target.innerText = hideText
                this.textMore = 'active'
                this.$refs.page_content_wrap.style.height = `${this.$refs.page_content.offsetHeight}px`
            } else {
                this.textMore = ''
                this.$refs.page_content_wrap.style.height = null
                e.target.innerText = showText
            }
        },
    },
}
