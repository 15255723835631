import {
    useAddFiles,
    useAddVideo,
    useGetVideoId,
    useRemoveThumb,
    useRotateThumb,
    useRemoveVideo,
    formDisabledToggle,
    validateName,
    validateEmail,
    useShowErrors,
} from '../../forms/formHelpers'

import axios from 'axios'

export default {
    data() {
        return {
            voteDescr: '',
            imgThumbs: [],
            formData: {
                vote: {
                    value: 0,
                    error: false,
                    showError: false,
                },
                pluses: {
                    value: '',
                    error: false,
                    showError: false,
                },
                minuses: {
                    value: '',
                    error: false,
                    showError: false,
                },
                email: {
                    value: '',
                    error: false,
                    showError: false,
                },
                name: {
                    value: '',
                    error: false,
                    showError: false,
                },
                msg: {
                    value: '',
                    error: false,
                    showError: false,
                },
                files: {
                    error: false,
                    showError: false,
                    errorMsg: '',
                    value: [],
                },
                videos: {
                    error: false,
                    showError: false,
                    value: [],
                },
            },
        }
    },
    computed: {
        videoThumbs() {
            return this.formData.videos.value.map((row) => useGetVideoId(row)) || []
        },
        disabled() {
            return this.formData.email.value === '' ||
                this.formData.email.error ||
                this.formData.name.value === '' ||
                this.formData.name.error ||
                this.formData.videos.error
                ? true
                : false
        },
    },
    methods: {
        validate(e, rule) {
            const rules = {
                name: () => validateName(this, this.formData.name),
                email: () => validateEmail(this, this.formData.email),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        starHover(idx) {
            const stars = document.querySelectorAll('.votes .star')
            stars.forEach((star, i) => {
                if (i <= idx - 1) {
                    star.classList.add('active')
                    star.classList.remove('not-active')
                } else {
                    star.classList.add('not-active')
                    star.classList.remove('active')
                }
            })
            this.voteDescr = stars[idx - 1].getAttribute('data-descr')
        },
        starsLeave() {
            let checked = false
            document.querySelectorAll('.votes .star').forEach((star) => {
                star.classList.remove('active')
                star.classList.remove('not-active')
                if (star.classList.contains('checked')) {
                    checked = true
                }
            })
            if (!checked) {
                this.voteDescr = ''
            }
        },
        checkVote(idx) {
            const stars = document.querySelectorAll('.votes .star')
            stars.forEach((star) => {
                star.classList.remove('checked')
            })
            for (let i = 0; i < stars.length; i++) {
                stars[i].classList.add('checked')
                if (i >= idx - 1) {
                    break
                }
            }
            this.formData.vote.value = parseInt(idx)
        },
        autogrow(e) {
            this.formData[e.target.getAttribute('name')].value = e.target.innerText
        },
        submit(e) {
            e.preventDefault()
            if (this.disabled) return

            this.$Loading.start('productReviewModal')
            formDisabledToggle(e.target, true)

            let formData = new FormData()
            this.formData.files.value.forEach((row, i) => {
                formData.append(`files[${i}]`, row.file)
                formData.append(`exif[${i}]`, row.exif)
            })

            this.formData.videos.value.forEach((row, i) => {
                formData.append(`video[${i}]`, row)
            })

            formData.append('name', this.formData.name.value)
            formData.append('email', this.formData.email.value)
            formData.append('msg', this.formData.msg.value)
            formData.append('vote', this.formData.vote.value)
            formData.append('pluses', this.formData.pluses.value)
            formData.append('minuses', this.formData.minuses.value)
            
            axios({
                url: e.target.getAttribute('action'),
                method: e.target.getAttribute('method'),
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            }).then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.$Loading.finish('productReviewModal')
                            formDisabledToggle(e.target, false)
                            e.target.reset()
                            this.$notify({
                                group: 'default',
                                text: response.data.message,
                            })
                        }, 300)
                    }
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.$Loading.finish('productReviewModal')
                        formDisabledToggle(e.target, false)
                        console.log(error)
                    }, 300)
                })
        },
        getVideo(e) {
            useAddVideo(this, e)
        },
        removeVideo(i) {
            useRemoveVideo(this, i)
        },
        addFiles(e) {
            useAddFiles(this, e)
        },
        removeThumb(i) {
            useRemoveThumb(this, i)
        },
        rotateThumb(i) {
            useRotateThumb(this, i)
        },
    },
}
