<template>
    <div>
        <slot name="default"></slot>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {
            active: false,
        }
    },
    mounted() {
        if (this.$refs.expand.classList.contains('active')) {
            this.active = true
        }
    },
    methods: {
        toggle() {
            this.active = !this.active

            const child = this.$refs.expandBody.children[0]
            if (!child) return

            if (this.active) {
                this.$refs.expand.classList.add('active')
                this.$refs.expandBody.style.height = `${child.scrollHeight}px`
                setTimeout(() => {
                    this.$refs.expandBody.style.height = null
                    this.$refs.expandBody.classList.add('active')
                }, 300)
                this.$emit('change', { show: true })
            } else {
                this.$refs.expand.classList.remove('active')
                this.$refs.expandBody.style.height = `${child.scrollHeight}px`
                setTimeout(() => {
                    this.$refs.expandBody.style.height = `0px`
                })
                this.$refs.expandBody.classList.remove('active')
                setTimeout(() => {
                    // this.$refs.expandBody.classList.remove('active')
                    this.$refs.expandBody.style.height = null
                }, 150)
                this.$emit('change', { show: false })
            }
        },
        show() {
            this.active = true

            const child = this.$refs.expandBody.children[0]
            if (!child) return

            this.$refs.expand.classList.add('active')
            this.$refs.expandBody.style.height = `${child.scrollHeight}px`
            setTimeout(() => {
                this.$refs.expandBody.style.height = null
                this.$refs.expandBody.classList.add('active')
            }, 150)
            this.$emit('change', { show: true })
        },
        hide() {
            this.active = false

            const child = this.$refs.expandBody.children[0]
            if (!child) return

            this.$refs.expand.classList.remove('active')
            this.$refs.expandBody.style.height = `${child.scrollHeight}px`
            setTimeout(() => {
                this.$refs.expandBody.style.height = `0px`
            })
            setTimeout(() => {
                this.$refs.expandBody.classList.remove('active')
                this.$refs.expandBody.style.height = null
            }, 150)
            this.$emit('change', { show: false })
        },
    },
}
</script>
