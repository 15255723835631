export default {
    props: {
        id: {
            type: Number,
        },
        value: {},
        model: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {
                id: null,
            },
        }
    },
    mounted() {
        if (this.$refs.input.getAttribute('checked') === 'checked') {
            this.$refs.input.checked = true
            this.onChange()
        }
    },
    methods: {
        onChange(e) {
            this.formData.id = this.id
            this.$emit('input', this.formData)
        },
    },
}
