export default {
    props: ['visible', 'showTimeout'],
    data() {
        return {
            active: false,
        }
    },
    mounted() {
        if (this.visible) {
            setTimeout(() => {
                this.active = true
                this.show()
            }, this.showTimeout || 0)
        }
        this.$refs.tooltip.addEventListener('mouseover', this.show)
        this.$refs.tooltip.addEventListener('mouseleave', this.hide)
    },
    methods: {
        hide() {
            setTimeout(() => {
                this.active = false
                this.$refs.tooltip.querySelector('.tooltip-body').classList.remove('active')
            }, 100)
        },
        show() {
            this.active = true
            setTimeout(() => {
                this.$refs.tooltip.querySelector('.tooltip-body').classList.add('active')
            }, 10)
        },
    },
}
