import { productsSliderInit } from '../../sliders'
import { pagin } from '../../pagin'
import axios from 'axios'

export default {
    name: 'Blog',
    data() {
        return {
            pagin: {
                paginate: null,
                limit: 24,
            },
            sort: {
                value: null,
                name: 'sort',
            },
        }
    },
    mounted() {
        productsSliderInit()
        this.initPagin()

    },
    methods: {
        showSortby() {
            this.$refs.sortby.onFocus()
        },
        sortChange() {
            this.currentSortby = this.$refs.sortby.display
        },
        initPagin() {
            this.pagin.paginate = pagin
            return this.pagin.paginate({
                wrapper: '.blog-grid',
                container: '.blog-list',
                btn: '.load-more-btn',
                limit: this.pagin.limit,
                onStart: () => {
                    this.$Loading.start('main')
                },
                onLoad: (state, response) => this.updateLoadMoreBtn(state, response),
            })
        },
        updateLoadMoreBtn(state, response) {
            if (response.data.total - state.total > state.limit) {
                state.btnMore.querySelector('span').innerText = state.limit
            } else {
                state.btnMore.querySelector('span').innerText = response.data.total - state.total
            }
            setTimeout(() => {
                this.$Loading.finish('main')
            }, 300)
        },
        formSubmit() {
            this.$refs.tabsForm.dispatchEvent(new Event('submit'))
        },
        tabsFormSubmit(e) {
            e.preventDefault()

            this.$Loading.start('main')

            const val = e.target.querySelector('[name=tags]:checked').value

            axios
                .get(val)
                .then((response) => {
                    this.$refs.blogGrid.setAttribute('data-url', val)
                    if (response.data.data) {
                        this.$refs.blogList.innerHTML = response.data.data
                    } else {
                        this.$refs.blogList.innerHTML = ``
                        paginState.btnMore.style.display = `none`
                        setTimeout(() => {
                            this.$Loading.finish('main')
                        }, 300)
                        return
                    }

                    const paginState = this.initPagin()

                    this.updateLoadMoreBtn(paginState, response)

                    if (response.data.items < this.pagin.limit) {
                        paginState.btnMore.style.display = `none`
                    } else {
                        paginState.btnMore.style.removeProperty('display')
                    }

                    setTimeout(() => {
                        this.$Loading.finish('main')
                    }, 300)
                })
                .catch((err) => {
                    this.$Loading.finish('main')
                    console.error(err)
                })
        },
    },
}
