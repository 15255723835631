import Swiper from 'swiper/bundle'

export default {
    data() {
        return {}
    },
    mounted() {
        const complectsSlider = new Swiper('.complects-slider', {
            slidesPerView: 1,
            spaceBetween: 16,
            navigation: {
                nextEl: '.complects-swiper-button-next',
                prevEl: '.complects-swiper-button-prev',
            },
            breakpoints: {
                320: {
                    pagination: {
                        el: '.swiper-pagination-complects',
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                    },
                },
                1024: {
                    pagination: {},
                },
            },
        })
        const productsSliderVert = new Swiper('.complects-products-slider', {
            direction: 'vertical',
            slidesPerView: 1,
            navigation: {
                nextEl: '.product-swiper-button-next',
                prevEl: '.product-swiper-button-prev',
            },
            on: {
                afterInit: (e) => {
                    this.calcComplectPrice(e)
                },
                slideChange: (e) => {
                    this.calcComplectPrice(e)
                },
            },
        })
    },
    methods: {
        calcComplectPrice(e) {
            let prices = {},
                totalPrices = {}
            const products = this.getCurrentProducts(e)
            prices = this.getProductPrices(products)
            totalPrices = this.calcItemPrice(prices)
            this.insertPrices(totalPrices, e.$el[0].closest('.complects-item'))
        },
        getCurrentProducts(e) {
            const parent = e.$el[0].closest('.complects-item')
            e.slides.forEach((el) => el.querySelector('.complects-product').classList.remove('active'))
            e.slides[e.realIndex].querySelector('.complects-product').classList.add('active')
            const products = parent.querySelectorAll('.complects-product.active')

            return products
        },
        getProductPrices(products) {
            const prices = []
            products.forEach((product) => {
                prices.push({
                    price: parseFloat(product.querySelector('.price-num').innerText.replace(/ /g, '')) || 0,
                    oldPrice:
                        (product.querySelector('.old-price') &&
                            parseFloat(product.querySelector('.old-price').innerText.replace(/ /g, ''))) ||
                        parseFloat(product.querySelector('.price-num').innerText.replace(/ /g, '')),
                    discount:
                        (product.querySelector('.chip.sale') &&
                            parseFloat(
                                product
                                    .querySelector('.chip.sale')
                                    .innerText.replace(/ /g, '')
                                    .replace('-', '')
                            )) ||
                        0,
                })
            })

            return prices
        },
        calcItemPrice(data = []) {
            const prices = {
                price: 0,
                oldPrice: 0,
                discount: 0,
            }
            data.forEach((item) => {
                let price = prices.price + item.price,
                    oldPrice = prices.oldPrice + item.oldPrice,
                    discount = oldPrice - price
                prices.price = price
                prices.oldPrice = oldPrice
                prices.discount = discount
            })

            prices.price = this.numberFormat(prices.price, 0, 3, ' ', '')
            prices.oldPrice = this.numberFormat(prices.oldPrice, 0, 3, ' ', '')
            prices.discount = this.numberFormat(prices.discount, 0, 3, ' ', '')

            return prices
        },
        insertPrices(prices, item) {
            item.querySelector('.complects-total .old-price-num').innerText = prices.oldPrice
            item.querySelector('.complects-total .price-num').innerText = prices.price
            item.querySelector('.complects-total .chip.sale .chip-control').innerText = `-${prices.discount}`
        },
        numberFormat(number, n, x, s, c) {
            const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = number.toFixed(Math.max(0, ~~n))

            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
        },
    },
}
