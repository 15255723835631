import {
    useAddFiles,
    useAddVideo,
    useGetVideoId,
    useRemoveThumb,
    useRotateThumb,
    useRemoveVideo,
    formDisabledToggle,
    validateName,
    validateEmail,
    useShowErrors,
} from '../../forms/formHelpers'

import axios from 'axios'

export default {
    data() {
        return {
            imgThumbs: [],
            formData: {
                email: {
                    value: '',
                    error: false,
                    showError: false,
                },
                name: {
                    value: '',
                    error: false,
                    showError: false,
                },
                msg: {
                    value: '',
                    error: false,
                    showError: false,
                },
                files: {
                    error: false,
                    showError: false,
                    errorMsg: '',
                    value: [],
                },
                videos: {
                    error: false,
                    showError: false,
                    value: [],
                },
            },
        }
    },
    computed: {
        videoThumbs() {
            return this.formData.videos.value.map((row) => useGetVideoId(row)) || []
        },
        disabled() {
            return this.formData.email.value === '' ||
                this.formData.email.error ||
                this.formData.name.value === '' ||
                this.formData.name.error ||
                this.formData.videos.error
                ? true
                : false
        },
    },
    methods: {
        validate(e, rule) {
            const rules = {
                name: () => validateName(this, this.formData.name),
                email: () => validateEmail(this, this.formData.email),
            }

            rules.hasOwnProperty(rule) && rules[rule]()
        },
        showErrors(input) {
            useShowErrors(this, input)
        },
        submit(e) {
            e.preventDefault()
            if (this.disabled) return

            this.$Loading.start('productQstModal')
            formDisabledToggle(e.target, true)

            let formData = new FormData()
            this.formData.files.value.forEach((row, i) => {
                formData.append(`files[${i}]`, row.file)
                formData.append(`exif[${i}]`, row.exif)
            })

            this.formData.videos.value.forEach((row, i) => {
                formData.append(`video[${i}]`, row)
            })

            formData.append('name', this.formData.name.value)
            formData.append('email', this.formData.email.value)
            formData.append('msg', this.formData.msg.value)

            axios({
                url: e.target.getAttribute('action'),
                method: e.target.getAttribute('method'),
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            }).then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.$Loading.finish('productQstModal')
                            formDisabledToggle(e.target, false)
                            e.target.reset()
                            this.$notify({
                                group: 'default',
                                text: response.data.message,
                            })
                        }, 300)
                    }
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.$Loading.finish('productQstModal')
                        formDisabledToggle(e.target, false)
                        console.error(error)
                    }, 300)
                })
        },
        getVideo(e) {
            useAddVideo(this, e)
        },
        removeVideo(i) {
            useRemoveVideo(this, i)
        },
        addFiles(e) {
            useAddFiles(this, e)
        },
        removeThumb(i) {
            useRemoveThumb(this, i)
        },
        rotateThumb(i) {
            useRotateThumb(this, i)
        },
    },
}
