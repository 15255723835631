import { numberFormat } from '../../utils'
import { formDisabledToggle } from '../../forms/formHelpers'
import axios from 'axios'
import CreditItem from './creditItem'

export default {
    data() {
        return {
            creditParts: 2,
            creditor: 1,
        }
    },
    components: {
        CreditItem,
    },
    props: {
        productPrice: {
            type: Number,
            default: 0,
        },
        productId: {
            type: Number,
        },
    },
    methods: {
        updateParts(val) {
            this.creditParts = val
        },
        updateCreditor(data) {
            this.creditParts = +data.min
            this.creditor = +data.id
        },
        numberFormat(number, n, x, s, c) {
            return numberFormat(number, n, x, s, c)
        },
        submit(e) {
            e.preventDefault()

            this.$Loading.start('creditModal')
            formDisabledToggle(e.target, true)

            alert(
                'SendData: ' +
                    JSON.stringify({
                        creditParts: this.creditParts,
                        creditorId: this.creditor,
                        productId: this.productId,
                    })
            )

            axios
                .post(e.target.getAttribute('action'), {
                    data: {
                        careditParts: this.careditParts,
                        creditorId: this.creditor,
                        productId: this.productId,
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        setTimeout(() => {
                            this.$Loading.finish('creditModal')
                            formDisabledToggle(e.target, false)
                        }, 300)
                    }
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.$Loading.finish('creditModal')
                        formDisabledToggle(e.target, false)
                        console.log(error)
                    }, 300)
                })
        },
    },
}
