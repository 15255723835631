import axios from "axios"

const HISTORY_KEY = 'search_history'

export default {
    name: 'Search',
    data() {
        return {
            history: [],
            q: '',
            focusin: false,
            searching: false,
            results: {
                keywords: [],
                categories: [],
                products: [],
                empty: false,
            }
        }
    },
    mounted() {
        if (localStorage.getItem(HISTORY_KEY)) {
            this.updateSearchHistory()
        } else {
            this.createSearchHistory()
        }

        const items = searchPlaceholders

        if (searchPlaceholders.length > 0) {
            let i = 0;
            let idx = 0
            let txt = items[idx];
            let speed = 50;
            const input = this.$refs.searchInput
            let dir = 'forw'

            let typeWriter = () => {
                if (this.q.length > 0) return

                if (i < txt.length && dir !== 'back') {
                    if (this.focusin) {
                        input.setAttribute('placeholder', '')
                        clearTimeout(typeWriter);
                        return
                    }
                    let text = input.getAttribute('placeholder') + txt.charAt(i)
                    input.setAttribute('placeholder', text)
                    i++;
                    let timeout = speed
                    if (i + 1 > txt.length) {
                        dir = 'back'
                        timeout = 1000
                    }
                    setTimeout(typeWriter, timeout);

                } else {
                    if (this.focusin) {
                        input.setAttribute('placeholder', '')
                        clearTimeout(typeWriter);
                        return
                    }
                    let text = input.getAttribute('placeholder').substring(0, input.getAttribute('placeholder').length - 1)
                    input.setAttribute('placeholder', text)
                    i--;
                    if (i <= 0) {
                        if (idx + 1 < items.length) {
                            idx = idx + 1
                            txt = items[idx]
                        } else {
                            idx = 0
                            txt = items[idx]
                        }
                        dir = 'forw'
                    }

                    setTimeout(typeWriter, speed - speed / 4);

                }
            }

            typeWriter()
            this.$refs.searchInput.addEventListener('focus', () => {
                this.focusin = true
                input.setAttribute('placeholder', '')
                i = 0
                speed = 50
                txt = items[idx]

            })
            this.$refs.searchInput.addEventListener('blur', () => {
                this.focusin = false
                typeWriter()
            })
        }
    },
    computed: {
        show() {
            if (this.history.length > 0 || this.results.keywords.length > 0 || this.results.categories.length > 0 || this.results.products.length > 0 || this.results.empty) {
                return true
            } else {
                return false
            }
        },
        showHistory() {
            if (this.history.length > 0 && this.results.keywords.length === 0 && this.results.categories.length === 0 && this.results.products.length === 0 && !this.results.empty) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        loaderShow() {
            this.$refs.searchLoader.classList.add('active')
        },
        loaderHide() {
            this.$refs.searchLoader.classList.remove('active')
        },
        createSearchHistory() {
            localStorage.setItem(HISTORY_KEY, JSON.stringify([]))
        },
        updateSearchHistory() {
            this.history = JSON.parse(localStorage.getItem(HISTORY_KEY)).slice(0, 20)
        },
        searchHistoryAdd(data) {
            const storage = JSON.parse(localStorage.getItem(HISTORY_KEY))
            if (!storage.includes(data)) {
                storage.push(data)
                localStorage.setItem(HISTORY_KEY, JSON.stringify(storage))
            }
        },
        searchHistoryRemoveItem(e, i) {
            e.preventDefault()
            const storage = JSON.parse(localStorage.getItem(HISTORY_KEY))
            const idx = storage.indexOf(this.history[i])
            this.history.splice(idx, 1)
            storage.splice(idx, 1)
            this.storageSave(HISTORY_KEY, storage)
            this.updateSearchHistory()
        },
        storageClear(key) {
            localStorage.setItem(key, JSON.stringify([]))
        },
        storageSave(key, data) {
            localStorage.setItem(key, JSON.stringify(data))
        },
        searchSubmit(e) {
            e.preventDefault()
            this.searchHistoryAdd(this.q)
            window.location.href = `${e.target.getAttribute('action')}?${this.$refs.searchInput.name}=${this.q}`
        },
        searchHistoryAddByItem(e, data) {
            e.preventDefault()
            this.searchHistoryAdd(data)
            window.location.href = `${this.$refs.searchForm.getAttribute('action')}?${this.$refs.searchInput.name}=${data}`
        },
        searchHistoryAddByProduct(e, i) {
            e.preventDefault()
            this.searchHistoryAdd(this.results.products[i].title)
            window.location.href = this.results.products[i].url
        },
        searchHistoryClear() {
            this.storageClear(HISTORY_KEY)
            this.updateSearchHistory()
        },
        searchChange(e) {
            this.loaderShow()
            if (this.q.length === 0) {
                this.reset()
                this.loaderHide()
                return
            }
            this.debounce(() => this.getData(e), e.target.getAttribute('data-debounce') || 1000)
            if (e.target.value.length > 0) {
                this.$refs.searchReset.classList.add('active')
            } else {
                this.$refs.searchReset.classList.remove('active')
            }
        },
        debounce(func, timeout) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                func()
            }, timeout)
        },
        reset() {
            this.results.keywords = []
            this.results.categories = []
            this.results.products = []
            this.results.empty = false
            this.$refs.searchReset.classList.remove('active')
        },
        getData(e) {
            if (this.q.length === 0) return
            const params = { [e.target.name]: this.q }
            axios.get(this.$refs.searchForm.getAttribute('data-api'), {
                params: {
                    ...params
                }
            }).then((response) => {
                this.results.keywords = []
                this.results.categories = response.data.data.categories
                this.results.products = []

                const val = this.q
                const regex = new RegExp(val, 'gi')

                response.data.data.keywords.forEach((row) => {
                    const text = row.trim()
                    if (text.match(regex) === null) {
                        this.results.keywords.push({
                            text: text,
                            displayText: text
                        })
                    } else {
                        this.results.keywords.push({
                            text: text,
                            displayText: text.replace(regex, `<b>${text.match(regex)[0]}</b>`)
                        })
                    }
                })

                response.data.data.products.forEach((row) => {
                    const text = row.title.trim()
                    if (text.match(regex) === null) {
                        this.results.products.push({
                            text: text,
                            displayText: text,
                            ...row
                        })
                    } else {
                        this.results.products.push({
                            text: text,
                            displayText: text.replace(regex, `<b>${text.match(regex)[0]}</b>`),
                            ...row
                        })
                    }
                })

                if (this.results.keywords.length === 0 && this.results.categories.length === 0 && this.results.products.length === 0) {
                    this.results.empty = true
                } else {
                    this.results.empty = false
                }

                this.loaderHide()
            })
        },
        searchReset() {
            this.q = ''
            this.reset()
            this.$refs.searchInput.focus()
        },
        searchToggle() {
            this.$refs.search.classList.toggle('active')
            this.searchFocus()
            if (this.$refs.search.classList.contains('active')) {
                setTimeout(() => {
                    this.$refs.searchInput.focus()
                }, 350)
            }
        },
        searchFocus(e) {
            this.$refs.searchDropdown.classList.add('active')
            this.$refs.searchOverlay.classList.add('active')
            const app = document.querySelector('#app')
            app.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
            app.classList.add('overflow')
        },
        searchBlur(e) {
            this.$refs.searchDropdown.classList.remove('active')
            this.$refs.searchOverlay.classList.remove('active')
            const app = document.querySelector('#app')
            app.classList.remove('overflow')
            app.style.paddingRight = null
        }
    }
}