import axios from 'axios'

export default {
    name: 'subscribe',
    data() {
        return {
            error: false,
        }
    },
    methods: {
        submit(e) {
            e.preventDefault()

            if (!this.checkEmail()) {
                this.error = true
            }

            if (this.error) return

            axios({
                url: e.currentTarget.getAttribute('action'),
                method: e.currentTarget.getAttribute('method'),
                data: {
                    email: this.$refs.email.value,
                },
            })
                .then((response) => {
                    this.$refs.email.value = ``
                    e.target.reset()
                    this.$notify({
                        group: 'default',
                        text: response.data.message,
                    })
                })
                .catch((err) => console.error(err))
        },
        change() {
            this.checkEmail() ? (this.error = false) : (this.error = true)
        },
        input() {
            if (this.checkEmail()) {
                this.error = false
            }
        },
        checkEmail() {
            let v = this.$refs.email.value
            let regex = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/
            return regex.test(v)
        },
    },
}
