import Vue from 'vue'

import { anchorScroll } from '../../../utils/anchorScroll'
import Swiper from 'swiper'
import { productsSliderInit } from '../../sliders'

import ProductComplects from './productComplects'
import Reviews from './reviews'
import Questions from './questions'
import VTabs from './tabs'
import Message from './message'

import ProductNotifyForm from './productNotifyForm'
import ProductQstForm from './productQstForm'
import ProductAskForm from './productAskForm'
import ProductReviewForm from './productReviewForm'
import CreditForm from './creditForm'

import GalleryModal from './galleryModal'
Vue.component('gallery-modal', GalleryModal)

Vue.component('reviews', Reviews)
Vue.component('questions', Questions)
Vue.component('message', Message)

Vue.component('product-notify-form', ProductNotifyForm)
Vue.component('product-qst-form', ProductQstForm)
Vue.component('product-ask-form', ProductAskForm)
Vue.component('product-review-form', ProductReviewForm)
Vue.component('credit-form', CreditForm)

export default {
    name: 'Product',
    components: {
        ProductComplects,
        VTabs,
    },
    data() {
        return {
            hiddenOptins: [],
        }
    },
    mounted() {
        productsSliderInit()
        const productGalleryNav = new Swiper('.product-gallery-nav', {
            direction: 'vertical',
            slidesPerView: 8,
            freeMode: true,
            watchSlidesProgress: true,
        })
        const productGalleryMain = new Swiper('.product-gallery-main', {
            slidesPerView: 1,
            thumbs: {
                swiper: productGalleryNav,
            },
            breakpoints: {
                320: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                    },
                },
                1024: {
                    pagination: {},
                },
            },
        })
        productGalleryNav.slides.forEach((slide, i) => {
            slide.addEventListener('mouseover', () => {
                productGalleryMain.slideTo(i)
            })
        })
        anchorScroll('.product-nav', 72)
        anchorScroll('.product-descr-rows', 72)
        anchorScroll('.page-header-bottom', 72)

        this.hiddenOptins = this.$refs.productOptions.querySelectorAll('tr.hidden')
        const links = this.$refs.productNav.querySelectorAll('.product-nav-link')

        const sectionObserve = {
            options: {
                threshold: [0, 0.25, 0.5, 0.75, 1], //0.25
                rootMargin: '-50% 0% -50% 0%',
            },
            callback: (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        links.forEach((el) => {
                            if (el.getAttribute('data-section') === entry.target.getAttribute('id')) {
                                el.classList.add('active')
                            } else {
                                el.classList.remove('active')
                            }
                        })
                    }
                })
            },
            targets: [...document.querySelectorAll('.product-section-observe')],
        }
        this.observing(sectionObserve.targets, sectionObserve.callback, sectionObserve.options)

        const ww = document.documentElement.clientWidth

        if (ww > 768) {
            this.fixedProductActions()
            this.fixedProductReviews()
            this.productActionsScroll()
        }

        const obs = new IntersectionObserver(
            () => {
                this.dispacthScroll()
            },
            {
                threshold: 0,
            }
        )
        obs.observe(document.querySelector('#product_reviews'))
        obs.observe(document.querySelector('#product_qst'))

        if (ww > 768 && ww <= 1024) {
            const gallery = document.querySelector('.product-gallery')
            const productMain = document.querySelector('.product-main')
            window.addEventListener('scroll', () => {
                if (window.pageYOffset + 73 > productMain.getBoundingClientRect().top + scrollY) {
                    productMain.classList.add('sticky')
                } else {
                    productMain.classList.remove('sticky')
                }

                if (
                    scrollY + gallery.getBoundingClientRect().height + 150 >=
                    productMain.getBoundingClientRect().height
                ) {
                    if (!gallery.classList.contains('sticky')) {
                        gallery.style.top = `${productMain.getBoundingClientRect().height -
                            gallery.getBoundingClientRect().height -
                            80}px`
                        gallery.style.position = 'absolute'
                        gallery.classList.add('sticky')
                    }
                } else {
                    gallery.classList.remove('sticky')
                    gallery.style.top = null
                    gallery.style.position = null
                }
            })
        }

        window.addEventListener('scroll', () => {
            window.pageYOffset + 1 > this.$refs.productNav.getBoundingClientRect().top + scrollY
                ? this.productNavStickyToggle(true)
                : this.productNavStickyToggle(false)
        })
    },
    methods: {
        optionChange(e) {
            const option = e.target
            document.querySelector('.product-main .price-num').innerText = option.getAttribute('data-price')
            document.querySelector('.product-main .old-price-num').innerText = option.getAttribute('data-old-price')
            const classes = ['instock', 'danger', 'outstock']
            document.querySelectorAll('.card-actions').forEach((el) => {
                classes.forEach((classname) => el.classList.remove(classname))
                el.classList.add(option.getAttribute('data-stock'))
            })
            document.querySelectorAll('.product-stock-status .stock').forEach((el) => (el.style.display = 'none'))
            document.querySelector(`.product-stock-status .stock.${option.getAttribute('data-stock')}`).style.display =
                'inline-flex'

            if (option.closest('.tag-filter').querySelector('.tab-filter-value')) {
                option.closest('.tag-filter').querySelector('.tab-filter-value').innerText = option.value
            }
        },
        productNavStickyToggle(bool) {
            if (bool) {
                this.$refs.productNav.classList.add('sticky')
                document.querySelector('.site-header').style.top = '-60px'
            } else {
                this.$refs.productNav.classList.remove('sticky')
                document.querySelector('.site-header').style.top = null
            }
        },
        productOptionsToggle(e) {
            this.hiddenOptins.forEach((row) => row.classList.toggle('hidden'))
            e.target.classList.toggle('active')
            e.target.innerText = e.target.classList.contains('active')
                ? e.target.getAttribute('data-hide')
                : e.target.getAttribute('data-show')
            this.dispacthScroll()
        },
        descrMoreToggle(e) {
            this.$refs.descrMore.classList.toggle('hidden')
            e.target.classList.toggle('active')
            e.target.innerText = e.target.classList.contains('active')
                ? e.target.getAttribute('data-hide')
                : e.target.getAttribute('data-show')
            this.dispacthScroll()
        },
        observing(targets = [], callback, options) {
            const observer = new IntersectionObserver(callback, options)
            if (targets.length > 0) {
                targets.forEach((el) => observer.observe(el))
            }
        },
        productActionsScroll() {
            window.addEventListener('scroll', (e) => {
                this.fixedProductActions()
                this.fixedProductReviews()
            })
        },
        fixedProductActions() {
            const section = document.getElementById('product-body')
            const productNav = document.querySelector('.product-nav').getBoundingClientRect().height
            const productReviewsRect = document.querySelector('.product-reviews-fixed').getBoundingClientRect()
            const sb = document.querySelector('.product-actions-fixed')
            const rect = section.getBoundingClientRect()
            const productInfo = document.querySelector('.product-info').getBoundingClientRect()
            const longread = document.querySelector('.longread')
            let longreadH = longread.getBoundingClientRect().height || 0

            if (productInfo.top < productNav + 24) {
                const sbrect = sb.getBoundingClientRect()
                sb.style.transform = null
                sb.style.width = sbrect.width + 'px'
                sb.style.left = sbrect.left + 'px'
                sb.style.top = `${productNav + 24}px`
                sb.style.position = 'fixed'
                if (Math.abs(rect.top) + sbrect.height + 80 + productReviewsRect.height >= rect.height) {
                    sb.style.position = `absolute`
                    sb.style.left = null
                    if (longreadH > 0) {
                        sb.style.top = null
                    }

                    sb.style.transform = `translateY(${rect.height -
                        longreadH -
                        sbrect.height -
                        80 -
                        productReviewsRect.height}px)`
                }
            } else {
                sb.style.position = 'absolute'
                sb.style.left = null
                sb.style.transform = null
                sb.style.top = null
            }
        },
        fixedProductReviews() {
            const section = document.getElementById('product-body')
            const productActionsRect = document.querySelector('.product-actions-fixed').getBoundingClientRect()
            const sb = document.querySelector('.product-reviews-fixed')
            const reviewsSection = document.querySelector('.product-feedback').getBoundingClientRect()
            const rect = section.getBoundingClientRect()

            if (sb.getBoundingClientRect().height > reviewsSection.height) {
                sb.style.position = 'static'
                sb.style.left = null
                sb.style.transform = null
                sb.style.top = null
                return
            }
            if (reviewsSection.top < productActionsRect.top + 40 + productActionsRect.height) {
                const sbrect = sb.getBoundingClientRect()
                sb.style.transform = null
                sb.style.width = sbrect.width + 'px'
                sb.style.left = sbrect.left + 'px'
                sb.style.top = `${productActionsRect.height + productActionsRect.top}px`
                sb.style.position = 'fixed'
                if (
                    Math.abs(rect.top) + sbrect.height + 16 - 40 + productActionsRect.height + productActionsRect.top >=
                    rect.height
                ) {
                    sb.style.top = null
                    sb.style.position = `absolute`
                    sb.style.left = null
                    sb.style.transform = `translateY(${reviewsSection.height - sbrect.height - 16}px)`
                }
            } else {
                sb.style.position = 'absolute'
                sb.style.left = null
                sb.style.transform = null
                sb.style.top = null
            }
        },
        dispacthScroll() {
            window.dispatchEvent(new Event('scroll'))
            window.scrollTo(0, scrollY + 1)
            window.scrollTo(0, scrollY - 1)
        },
    },
}
