import FilterSlider from './filterSlider'

export default {
    props: ['show'],
    name: 'filterSection',
    components: {
        FilterSlider,
    },
    data() {
        return {
            loaded: false,
            hiddenFiltersCount: 0,
            active: false,
            reset: false,
        }
    },
    mounted() {
        if (document.documentElement.clientWidth >= 1024) {
            if (this.show) {
                this.active = true
            }
        }
        this.hiddenFiltersCount = this.$refs.filterSection.querySelectorAll('.filter-item.hidden').length;
        this.loaded = true

        const inputs = this.$refs.filterSection.querySelectorAll('input')
        this.checkFilterState()
        if (document.documentElement.clientWidth < 1024) {
            if (inputs.length > 0) {
                inputs.forEach(input => {
                    input.addEventListener('change', e => {
                        let f = false
                        inputs.forEach(el => {
                            if (el.type === 'checkbox') {
                                if (el.checked) {
                                    f = true
                                }
                            } else {
                                if (el.value.length > 0) {
                                    f = true
                                }
                            }
                        })
                        this.reset = f

                        if (this.reset) {
                            let selected = {
                                name: null,
                                values: null,
                            }
                            let values = []
                            inputs.forEach(el => {
                                if (el.type === 'checkbox' && el.checked) {
                                    values.push(el.closest('.checkbox-wrap').querySelector('.checkbox-val').innerText)
                                } else if (el.closest('.filter-slider')) {
                                    values = [
                                        el.closest('.filter-slider').querySelector('.min-price').value + ' - ' +
                                        el.closest('.filter-slider').querySelector('.max-price').value,
                                    ]
                                }
                            })

                            selected.values = values
                            selected.name = this.$refs.filterSection.querySelector('.filter-section-title').innerText.trim()
                            let included = false
                            this.$parent._data.selected.forEach((row, i) => {
                                if (row.name.trim() === selected.name) {
                                    included = i

                                }
                            })
                            if (values.length > 0) {
                                if (included === false) {
                                    this.$parent._data.selected.push(selected)
                                } else {
                                    this.$parent._data.selected[included].values = selected.values
                                }
                            } else {
                                this.$parent._data.selected.splice(included, 1)
                            }
                            this.$refs.filterSection.querySelector('.filter-section-header').style.display = 'none'
                        } else {
                            const name = this.$refs.filterSection.querySelector('.filter-section-title').innerText.trim()
                            this.$parent._data.selected.forEach((row, i) => {
                                if (row.name.trim() === name) {
                                    this.$parent._data.selected.splice(i, 1)
                                }
                            })

                            this.$refs.filterSection.querySelector('.filter-section-header').style.display = null
                        }
                    })
                })
            }
        }

    },
    methods: {
        checkFilterState() {
            const inputs = this.$refs.filterSection.querySelectorAll('input')
            if (inputs.length > 0) {
                inputs.forEach(input => {
                    if (input.type === 'checkbox') {
                        if (input.checked) {
                            this.reset = true
                        }
                    }
                })
            }
        },
        filtersSubmit() {
            this.toggle()
        },
        filterSectionReset() {
            const inputs = this.$refs.filterSection.querySelector('.filter-section-rows').querySelectorAll('input')
            if (inputs.length > 0) {
                inputs.forEach(input => {
                    if (input.type === 'checkbox') {
                        input.checked = false
                    } else if (input.closest('.filter-slider')) {
                        input.value = input.getAttribute('data-value')
                        const parent = input.closest('.filter-slider')
                        const minmax = {
                            min: parent.querySelector('.filter-slider-min').value,
                            max: parent.querySelector('.filter-slider-max').value,
                        }
                        this.$refs.filterSlider.$refs.sliderRange.setValue([minmax.min, minmax.max])
                    }
                    // else {
                    //     input.value = null
                    // }
                })

                const name = this.$refs.filterSection.querySelector('.filter-section-title').innerText.trim()
                this.$parent._data.selected.forEach((row, i) => {
                    if (row.name.trim() === name) {
                        this.$parent._data.selected.splice(i, 1)
                    }
                })

                this.$refs.filterSection.querySelector('.filter-section-header').style.display = null
            }
            this.reset = false
        },
        toggle() {
            if (document.documentElement.clientWidth >= 1024) {
                this.active = !this.active
                this.$refs.filterSection.querySelector('.arrow').classList.remove('active')
                this.$refs.filterSection.querySelector('.filter-section-body').classList.remove('active')
                if (this.active) {
                    this.$refs.filterBody.style.height = `${this.$refs.filterRows.scrollHeight}px`
                    setTimeout(() => {
                        this.$refs.filterBody.style.height = null
                        this.$refs.filterBody.classList.add('active')
                    }, 300);
                } else {
                    this.$refs.filterBody.style.height = `${this.$refs.filterRows.scrollHeight}px`
                    setTimeout(() => {
                        this.$refs.filterBody.style.height = `0px`
                    })
                    setTimeout(() => {
                        this.$refs.filterBody.classList.remove('active')
                        this.$refs.filterBody.style.height = null
                    }, 300)
                }
            } else {
                this.$refs.filterSection.closest('.filter-section-wrap').classList.toggle('active')
                this.$refs.filterBody.classList.toggle('active')
            }
        },
        enter(el) {
            console.log(el.style.height = `${el.scrollHeight}px`)
        },
        showFilters(e) {
            const self = e.currentTarget
            this.$refs.filterSection.querySelectorAll('.filter-item.hidden').forEach((el) => el.classList.toggle('show'))
            self.classList.toggle('active')
            self.innerText = self.classList.contains('active') ? self.getAttribute('data-hide') : self.getAttribute('data-show')
        }
    }
}