import axios from 'axios'

export default {
    name: 'OrderCancel',
    props: ['id'],
    data() {
        return {
            active: false,
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault()
            this.$Loading.start('orderCancel')
            axios({
                url: e.target.getAttribute('action'),
                method: e.target.getAttribute('method'),
                params: {
                    id: this.id,
                },
                data: {
                    id: this.id,
                },
            })
                .then((response) => {
                    setTimeout(() => {
                        const order = document.querySelector(`[data-id="${this.id}"]`)
                        const orderStatus = order.querySelector('.order-status')
                        const orderStatusName = order.querySelector('.order-status-name')
                        orderStatus.classList.forEach((classname) => {
                            if (classname !== 'order-status') {
                                orderStatus.classList.remove(classname)
                            }
                        })

                        orderStatus.classList.add('cancelled')
                        orderStatusName.innerText = response.data.data.status.name

                        this.$Loading.finish('orderCancel')
                        this.$notify({
                            group: 'default',
                            text: response.data.message,
                        })
                        this.$modal.hide('orderCancel')
                    }, 300)
                })
                .catch((err) => {
                    this.$Loading.finish('orderCancel')
                    console.error(err)
                })
        },
    },
}
