const numberFormat = (number, n, x, s, c) => {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = number.toFixed(Math.max(0, ~~n))

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
}

const phonePrefix = (model, val, prefix) => {
    model.value = val.length < prefix.length ? prefix : val
}

export { numberFormat, phonePrefix }
