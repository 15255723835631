// plugin.js
import pageLoader from './ProgressBar.vue'

function assign (target, source) { // eslint-disable-line no-unused-vars
    for (var index = 1, key, src; index < arguments.length; ++index) {
        src = arguments[index];

        for (key in src) {
            if (Object.prototype.hasOwnProperty.call(src, key)) {
                target[key] = src[key];
            }
        }
    }

    return target
}


    
// The install method will be called with the Vue constructor as the first argument, along with possible options
function install (Vue, options = {}) {
    const isVueNext = Vue.version.split('.')[0] === '2'
    const inBrowser = typeof window !== 'undefined'

    const DEFAULT_OPTION = {
        show: false,
        name: '',
    }
    
    let Loading = {
        $vm: null,
        init (vm) {
            this.$vm = vm
        },
        start(name) {
            // console.log(this.$vm)
            this.$vm.LOADING.options.name = name
            this.$vm.LOADING.options.show = true
        },
        finish(name) {
            this.$vm.LOADING.options.name = name
            this.$vm.LOADING.options.show = false
        }
    }

    const loadingOptions = assign(DEFAULT_OPTION, options)

    const PageLoaderEventBus = new Vue({
        data: {
            LOADING: {
                options: loadingOptions
            }
        }
    })

    if (inBrowser) {
        window.PageLoaderEventBus = PageLoaderEventBus
        Loading.init(PageLoaderEventBus)
    }

    Vue.component('progress-bar', pageLoader)
    Vue.prototype.$Loading = Loading
}

export default { install }