import { productsSliderInit, cardsSliderInit } from '../../sliders'
import FilterSection from '../../filterSection'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
    name: 'Sale',
    components: {
        FilterSection,
        VueSkeletonLoader,
    },
    data() {
        return {
            loaded: false,
            currentSortby: '',
            sort: {
                value: null,
                name: 'sort',
            },
        }
    },
    mounted() {
        productsSliderInit()
        cardsSliderInit()

        this.loaded = true
        this.currentSortby = this.$refs.sortby.display
        if (document.documentElement.clientWidth > 768) {
            window.addEventListener('scroll', () => {
                window.pageYOffset + 1 > this.$refs.filter_header.getBoundingClientRect().top + scrollY
                    ? this.$refs.filter_header.classList.add('sticky')
                    : this.$refs.filter_header.classList.remove('sticky')
            })
        }
    },
    methods: {
        showSortby() {
            this.$refs.sortby.onFocus()
        },
        sortChange() {
            this.currentSortby = this.$refs.sortby.display
        },
        expandChange(data) {
            const btn = this.$refs.descr.querySelector('.expand-title')
            btn.innerText = data.show ? btn.getAttribute('data-hide') : btn.getAttribute('data-show')
        },
    },
}
