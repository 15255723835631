export default {
    name: 'CheckOnline',
    data() {
        return {
            active: false,
        }
    },
    mounted() {
        window.addEventListener('load', () => {
            window.addEventListener('online', this.updateOnlineStatus)
            window.addEventListener('offline', this.updateOnlineStatus)
        })
    },
    methods: {
        updateOnlineStatus(e) {
            this.active = !navigator.onLine

            const wrap = document.getElementById('app')
            const site = document.querySelector('.site')
            if (this.active) {
                site.style.transform = `translateY(-${window.pageYOffset}px)`
                wrap.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
                wrap.classList.add('overflow')
            } else {
                wrap.classList.remove('overflow')
                wrap.style.paddingRight = null
                site.style.transform = null
                window.scrollTo(0, this.scroll)
            }
        },
    },
}
