import { productsSliderInit, brandsSwiperInit } from '../../sliders'

export default {
    name: 'Categories',
    data() {
        return {}
    },
    mounted() {
        brandsSwiperInit(this)
        productsSliderInit()
    },
}
