export default {
    data() {
        return {

        }
    },
    methods: {
        cartProductPlus(e) {
            e.preventDefault()
            const input = e.currentTarget.parentElement.querySelector('input')
            input.value = +input.value + 1
            input.dispatchEvent(new Event('change', { bubbles: true }))
            this.loading(true)
            setTimeout(() => {
                this.loading(false)
            },2000)
        },
        cartProductMinus(e) {
            e.preventDefault()
            const input = e.currentTarget.parentElement.querySelector('input')
            if(+input.value === 1) return
            input.value = +input.value - 1
            input.dispatchEvent(new Event('change', { bubbles: true }))
            this.loading(true)
            setTimeout(() => {
                this.loading(false)
            },2000)
        },
        loading(bool) {
            if(bool) {
                this.$refs.minicart.classList.add('loading')
                this.$Loading.start('cart')
            } else {
                this.$refs.minicart.classList.remove('loading')
                this.$Loading.finish('cart')
            }
        }
    }
}