import { numberFormat } from '../../../utils'
import VueSlider from 'vue-slider-component'

export default {
    props: {
        id: {
            type: Number,
        },
        value: {},
        model: {
            type: Object,
        },
        min: {
            type: Number,
            default: 2,
        },
        max: {
            type: Number,
            default: 0,
        },
        price: {
            type: Number,
            default: 0,
        },
        defaultVal: {
            type: Number,
            default: 0,
        },
    },
    components: {
        VueSlider,
    },
    data() {
        return {
            formData: {
                id: null,
                creditParts: 0,
            },
        }
    },
    computed: {
        sliderOptions() {
            return { min: this.min, max: this.max, height: 2, dotSize: 24 }
        },
    },
    created() {
        this.formData.creditParts = this.formData.creditParts <= this.min && this.min
        this.formData.creditParts = this.defaultVal >= this.min ? this.defaultVal : this.formData.creditParts
    },
    mounted() {
        if (this.$refs.input.getAttribute('checked') === 'checked') {
            this.$refs.input.checked = true
            this.onChange()
        }
    },
    methods: {
        onChange(e) {
            this.formData.id = this.id
            this.$emit('input', this.formData)
        },
        numberFormat(number, n, x, s, c) {
            return numberFormat(number, n, x, s, c)
        },
        changeVal(val) {
            this.$emit('update-credit-parts', val)
        },
    },
}
