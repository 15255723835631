const useAddFiles = (vm, e) => {
    let uploadedFiles = e.target.files
    for (let i = 0; i < uploadedFiles.length; i++) {
        if (vm.formData.files.value.length >= 12) {
            vm.formData.files.error = true
            vm.formData.files.showError = true
            vm.formData.files.errorMsg = e.target.getAttribute('data-error-limit')
            break
        }
        if (!/\.(jpe?g|png|gif)$/i.test(uploadedFiles[i].name)) {
            continue
        }
        let f = true
        for (let k = 0; k < vm.formData.files.value.length; k++) {
            if (vm.formData.files.value[k].file.name === uploadedFiles[i].name) {
                f = false
                break
            }
        }
        if (!f) continue
        vm.formData.files.value.push({
            exif: 0,
            file: uploadedFiles[i],
        })
    }
    getImagePreviews(vm)
}

const getImagePreviews = (vm) => {
    for (let i = 0; i < vm.formData.files.value.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(vm.formData.files.value[i].file.name)) {
            let reader = new FileReader()
            reader.addEventListener('load', () => {
                let f = true
                for (let k = 0; k < vm.imgThumbs.length; k++) {
                    if (vm.imgThumbs[k].url === reader.result) {
                        f = false
                        break
                    }
                }
                if (f) {
                    vm.imgThumbs.push({
                        url: reader.result,
                        exif: 0,
                    })
                }
            })
            reader.readAsDataURL(vm.formData.files.value[i].file)
        }
    }
}

const useAddVideo = (vm, e) => {
    if (e.target.value === '') {
        vm.formData.videos.error = false
        vm.showErrors(e)
        return
    }

    const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi
    const url = e.target.value
    const error = e.target.closest('.form-group').querySelector('.error-msg')
    if (url.match(regex) === null) {
        error.innerText = e.target.getAttribute('data-error-default')
        vm.formData.videos.error = true
        vm.showErrors(e)
        return
    }
    if (vm.formData.videos.value.length >= 12) {
        error.innerText = e.target.getAttribute('data-error-limit')
        vm.formData.videos.error = true
        vm.showErrors(e)
        return
    }
    if (vm.formData.videos.value.includes(url)) {
        error.innerText = e.target.getAttribute('data-error-dublicate')
        vm.formData.videos.error = true
        vm.showErrors(e)
        return
    } else {
        vm.formData.videos.error = false
        vm.showErrors(e)
        vm.formData.videos.value.push(url)
        e.target.value = ''
    }
}

const useGetVideoId = (url) => {
    const q = url.split('?')[1]
    const params = {}
    if (q) {
        q.split('&').forEach((row) => {
            let tmp = row.split('=')
            params[tmp[0]] = tmp[1]
        })
    }
    let id = null
    if (params.hasOwnProperty('v')) {
        id = params.v
        return `https://img.youtube.com/vi/${id}/0.jpg`
    }
}

const useRemoveThumb = (vm, i) => {
    vm.formData.files.error = false
    vm.formData.files.showError = false
    vm.formData.files.errorMsg = ''
    vm.imgThumbs.splice(i, 1)
    vm.formData.files.value.splice(i, 1)
}

const useRemoveVideo = (vm, i) => {
    vm.formData.videos.value.splice(i, 1)
}

const useRotateThumb = (vm, i) => {
    const exif = vm.formData.files.value[i].exif + 90
    vm.formData.files.value[i].exif = exif
    vm.imgThumbs[i].exif = exif
}

const required = (val) => {
    return val.length === 0 ? false : true
}

const checkEmail = (val) => {
    let v = val
    let regex = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/
    return regex.test(v)
}

const checkDate = (val) => {
    const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    return regex.test(val)
}

const isPhone = (val, length = 9) => {
    var regex = /[()]|-|\+/gi
    var phoneVal = val.replaceAll(regex, '')
    var regPhone = /^\+?[0-9_. -()]+$/
    return regPhone.test(phoneVal) && phoneVal.length >= length ? true : false
}

const formDisabledToggle = (target, bool) => {
    target.disabled = bool
    target.querySelectorAll('input, textarea, button').forEach((el) => (el.disabled = bool))
}

const validateRequired = (vm, input) => {
    // if (e.target.value.length >= 2 && !/\d/gi.test(e.target.value)) {
    //     vm.formData[e.target.name].error = false
    //     vm.showErrors(e)
    // } else {
    //     vm.formData[e.target.name].error = true
    // }
    if (required(input.value)) {
        input.error = false
        vm.showErrors(input)
    } else {
        input.error = true
    }
}

const validateName = (vm, input) => {
    // if (e.target.value.length >= 2 && !/\d/gi.test(e.target.value)) {
    //     vm.formData[e.target.name].error = false
    //     vm.showErrors(e)
    // } else {
    //     vm.formData[e.target.name].error = true
    // }
    if (input.value.length >= 2 && !/\d/gi.test(input.value)) {
        input.error = false
        vm.showErrors(input)
    } else {
        input.error = true
    }
}

const validateEmail = (vm, input) => {
    // let status = checkEmail(vm.formData[e.target.name].value)
    // if (status) {
    //     vm.formData[e.target.name].error = false
    //     vm.showErrors(e)
    // } else {
    //     vm.formData[e.target.name].error = true
    // }
    let status = checkEmail(input.value)
    if (status) {
        input.error = false
        vm.showErrors(input)
    } else {
        input.error = true
    }
}

const validateDate = (vm, input) => {
    let status = checkDate(input.value)
    if (status) {
        input.error = false
        vm.showErrors(input)
    } else {
        input.error = true
    }
}

const validatePhone = (vm, input, length) => {
    if (isPhone(input.value, length)) {
        input.error = false
        vm.showErrors(input)
    } else {
        input.error = true
    }
}

const useShowErrors = (vm, input) => {
    input.showError = input.error ? true : false
}

export {
    useAddFiles,
    useAddVideo,
    useGetVideoId,
    useRemoveThumb,
    useRemoveVideo,
    useRotateThumb,
    checkEmail,
    formDisabledToggle,
    validateName,
    validateEmail,
    validatePhone,
    validateRequired,
    useShowErrors,
    validateDate,
}
